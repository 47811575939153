/* eslint-disable import/no-anonymous-default-export */
export default {
    home: {
        title: "Bilişim ve Siber Güvenlik",
        link: "/",
        keywords: "siber güvenlik, bilişim, ortam dinlemesi, zafiyet tarama, ip araçları, siber güvenlik danışmanlığı",
        description: "Siber Güvenlik, Bilişim Güvenliği, Ortam Güvenliği, Pentest, Danışmanlık ve Eğitim alanlarında hizmet verilmektedir.",
        slogan: "Bilişimdeki Defans Gücünüz",
        videoButton: true,
        orangeTitle: "32 Yıllık Tecrübe",
        // CONSULTANCY
        dataConsult: {
            title: "Danışmanlık",
            isoTitle: "Siber Güvenlik",
            p: "Network sistemleri, yazılım siber güvenlik mimarisi, bulut güvenliği, fiziki ortam ve şirket güvenlik politikalarının oluşturulması, geliştirilmesi ve denetim hizmeti verilmektedir.",
            isoLink: "/hizmetler/danismanlik/siber",
            gdprTitle: "KVKK",
            p1: "KVKK’nın 2 temel unsuru olan bilişim güvenliği ve süreç uzmanlığı konularında uzman ekipleri ile konuya %100 hakimiyet ve çözüm sunmaktadır. ",
            gdprLink: "/hizmetler/danismanlik/kvkk",
            domainTitle: "Domain",
            domainLink: "/hizmetler/danismanlik/domain",
            p2: "Müşterilerimizin sahip oldukları veya olacakları alan adlarının tüm detaylar incelenip çözüm önerileri hizmeti sağlanmaktadır."
        },
        toolsData: {
            title: "Araçlar",
            passwordTitle: "Lokasyon Araçları",
            passwordItem: [
                { name: "IP8", link: "https://ip8.com" },
                { name: "IP Lokasyon", link: "/araclar/geoip" },
                { name: "Ters DNS Kaydı", link: "/araclar/ters-dns" },
            ],
            passwordDefinition: "IP konum ve detay ile tersine DNS kayıt sorgulama hizmeti.",
            ipTitle: "Güvenlik Araçları",
            ipTools: [
                // { name: "NS3", link: "https://ns3.com" },
                { name: "Mail1", link: "https://mail1.io" },
                { name: "ŞifreNet", link: "https://şifre.net" },
                { name: "Şifre Oluşturucu", link: "/araclar/sifre-olusturucu" },
                { name: "Parola Oluşturucu", link: "/araclar/parola-olusturucu" }
            ],
            ipDefinition: "Spamlardan koruma ve güvenli şifre/parola oluşturma hizmeti.",
            domainDetailTitle: "Domain Ayrıntıları",
            otherToolsTitle: "Araştırma Araçları",
            otherToolsItem: [
                { name: "CyberChef", link: "https://cyberchef.org" },
                { name: "DNSLeakTest", link: "https://dnsleaktest.org" },
            ],
            otherDefinition: "Verilerin analizi, kodların çözümü ve DNS sızıntıların tespiti."
        },
        itDatas: {
            upTitle: "Veri Güvenliği",
            upParagraf: "Teknoloji Hırsızlığının tespiti, disk ve network analizleriyle artık verileriniz DEF24 tarafından koruma altına alınmaktadır",
            upLink: '/hizmetler/bilisim/veri-guvenligi',
            buttonName: 'İncele',
            belowLink: '/hizmetler/bilisim/tersine-muhendislik',
            belowTitle: "Tersine Mühendislik Hizmeti",
            belowPargraf: "Firmamız aktif ve pasif yöntemlerle açık kaynak ve derlenmiş dijital materyalleri inceleyerek tespit ve çözüm raporları sunmaktadır.",
            upperAlt: "veri güvenligi",
            belowAlt: "tersine muhendislik"
        },
        pentestDatas: {
            title: "Önemli Hizmetlerimiz",
            subTitle: "Sızma Testleri",
            definitionOne: "Kuruluşunuza gelebilecek tüm saldırılar, kapsamlı testlerimizle simülasyonları gerçekleştirilmektedir.",
            definitionTwo: "Sistemlerinizin zafiyet içeren unsurlarına yönelik çözüm önerileri raporlanmaktadır.",
            buttonName: "İncele",
            link: "/hizmetler/siber/sizma-testi",
        },
        tscmDatas: {
            title: "Ortam Güvenliği",
            definitions: "Sahip olduğumuz bilişim deneyimi ve teknolojik cihazlarla, radyo frekans analizleri ve kızılötesi incelemeleri gerçekleştirilerek müşterilerimize güvenli ortam sağlamaktayız.",
            link: "/hizmetler/ortam-guvenligi",
            buttonName: "İncele",
        },
        whyDatas: {
            firstTitle: 'NEDEN',
            secondTitle: "DEF24'ü Seçmeniz için Nedenler",
            items: [
                {
                    src: '/images/whychoose/knowledge.png',
                    alt: "tecrübe ve deneyim",
                    lowerTitle: '32 Yıllık Birikim',
                    definition: "Almanya'da faaliyet gösteren firmamızdan bağımsız olarak Türkiye ve ABD'de DEF24, Bilişim ve Savunma sektörlerinde hizmet vermeye başlamıştır.",
                    delay: '600'
                },
                {
                    src: '/images/whychoose/global.png',
                    alt: "geniş hizmet ağı",
                    lowerTitle: 'Geniş Hizmet Ağı',
                    definition: 'Siber Güvenlik, Bilişim Güvenliği, Ortam Güvenliği, Danışmanlık ve Eğitim alanlarında olmak üzere 5 ana kategoride global hizmet vermektedir.',
                    delay: '800'
                },
                {
                    src: '/images/whychoose/protect-sword.png',
                    alt: "tespit ve engelleme",
                    lowerTitle: 'Tespit ve Engelleme',
                    definition: 'Siber Güvenlik, Bilişim Güvenliği ve Ortam Güvenliğine yönelik tespit ve engelleme faaliyetleri gelişmiş ve son teknolojik cihazlarla gerçekleştirilmektedir. ',
                    delay: '1000'
                },
                {
                    src: '/images/whychoose/osint.png',
                    alt: "domain ip sorgu",
                    lowerTitle: 'Gelişmiş IP Sorgulaması',
                    definition: 'Gelişmiş altyapılarımızla IP ile ilgili aklınıza gelebilecek sorulara yanıt verilmektedir.',
                    delay: '300'
                },
                {
                    src: '/images/whychoose/professional.png',
                    alt: "profosyonel ekip",
                    lowerTitle: 'Profesyonel ve Uzman Ekip',
                    definition: 'Hizmet vereceğimiz tüm müşterilerimizin ihtiyaçlarına profesyonel yaklaşım sergilenmekte, uzman ekiplerimiz tarafından çözüm sunulmaktadır.',
                    delay: '500'
                }
            ]
        }

    },
    about: {
        title: "Kurumsal",
        link: "/kurumsal",
        keywords: "kurumsal, hakkımızda, insan kaynakları",
        description: "Siber Güvenlik, Bilişim Güvenliği, Ortam Güvenliği, Eğitim ve Danışmanlık konularında dünya standartlarında DEF24 Inc şirketi olarak hizmet vermektedir.",
        definitionOne: "DEF24, 32 yıllık network, yazılım, bilişim ve donanım konularındaki tecrübelerin, bir arada harmanlanarak",
        definitionTwo: "Siber Güvenlik, Bilişim Güvenliği, Ortam Güvenliği, Eğitim ve Danışmanlık",
        definitionThree: "konularında dünya standartlarında hizmet sağlamak amacıyla, Almanya'da faaliyet gösteren firmamızdan bağımsız olarak ",
        definitionFour: "ABD'de",
        definitionFive: " DEF24 Inc şirketi kurulmuştur.",
        visionTitle: "Vizyonumuz",
        visionPar: "Bilişim alanında gelişen son teknolojileri kontrol altında tutarak maksimum kabiliyet ile markamızı Türkiye ve Dünya da en iyiler listesindeki yerini üst basamaklara çıkarmak.",
        missionTitle: "Görevimiz",
        missionPar: "Bilişim sektöründe oluşacak problemleri, problemlerin nedenlerini ve problemlerin çözümlerini, Egitim, Farkındalık çalışmaları ve Danışmanlık faaliyetleri kapsamında kullanıcılara aktarabilmek, güncel ve en iyi çözümler üretebilmek.",
        whyUsTitle: "Niçin Biz",
        whyUsPar: "DEF24 32 yıllık deneyimiyle; Siber Güvenlik, Bilişim Güvenliği ve Ortam Güvenliği alanlarındaki tespit ve engelleme faaliyetlerini profesyonel personelle ve teknolojik cihazlarla gerçekleştirmektedir.",
        humanResources: {
            title: "İnsan Kaynakları",
            definitionOne: "DEF24 Inc olarak çalışanlarımızın kendilerini, alanlarında geliştirmek, becerilerini artırmak ve sosyal yaşamlarını kaliteli devam ettirebilmelerini desteklemekteyiz. Bu kapsamda alınacak sektör eğitimleri, sertifikaları ve sınavlarına şirketimizce olanak ve destek sağlanmaktadır. Sizlerde DEF24 ekibinin parlayan bir taşı olmak isterseniz,",
            mail: 'ik@def24.com',
            definitionTwo: "adresine CV'nizi iletebilirsiniz. ",
            definitionThree: "Şirketimizde kadın istihdamına önem verilmektedir.",
        },
        photoParag: "Bilişim ve Siber Güvenlikte en gelişmiş teknolojik cihazlarla ve kalifiye personeli ile sizlere dünya stardartlarında hizmet sunar."
    },
    contact: {
        title: "İletişim",
        link: "/iletisim",
        keywords: "iletişim bilgileri",
        description: "Bilişim ve Siber Güvenlik alanlarındaki sorularınıza cevap bulabilmek için mail veya telefon ile kolayca ulaşabilirsiniz.",
        questionTitle: "Bilgi ve talepleriniz için bize ulaşabilirsiniz...",
        company: "Şirket Merkezi, Miami, ABD.",
        companyOffice: "Teknik Ofis, Antalya, Türkiye.",
        placeHolderOne: "Adınız",
        placeHolderTwo: "Email Adresi",
        placeHolderThree: "Konu",
        placeHolderFour: "Mesajınız",
        buttonName: "Mesaj Gönder",
        contactNumber: "+1-305-5150578",
        contactNumberTr: "+1-305-5150578",
        contactAdress: "Def24 Inc. 815 Ponce De Leon Blvd Coral Gables FL 33134 USA",
        coordinatesLat: 25.7638449,
        coordinatesLong: -80.2591029,
        contactAdressTr: "Çaybaşı Mah. 1358 Sok. Premier Plaza 07100 Antalya Türkiye",
        coordinatesLatTr: 36.8876831,
        coordinatesLongTr: 30.7219744,
        success: "Başarılı",
        error: "Hatalı",
        successMessage: "Mesajınız tarafımıza ulaşmıştır. En kısa zamanda sizlere dönüş yapılacaktır.",
        errorMessage: "Hata meydana geldi, mesajınızı tekrar gönderiniz.",
    },
    pentest: {
        title: "Sızma Testleri",
        link: "/hizmetler/siber/sizma-testi",
        keywords: "sızma testi, pentest, fiziki test, router testi, wi-fi testi, sosyal mühendislik testi, network testi, yazılım uygulama testi, mobil cihaz testi, server testi, ağ cihazları testi",
        description: "Sızma testlerimiz: Wifi, Fiziki, Sosyal Mühendislik, Network, Yazılım ve Uygulama, Mobil Cihaz, Server ve Ağ Cihazları olarak gerçekleştirilmektedir.",
        pentestDatas: {
            upperTitle: "Siber Güvenlik",
            documentTitle: "Sızma Testleri",
            pTitleOne: 'Sızma Testi (PENTEST)',
            pOne: 'Sızma testi; uzman kişilerce sistemlerin bilişim altyapılarının güvenliğini değerlendirmek üzere gerçekleştirilen saldırı simülasyonudur.',
            pTwo: 'Müşterilerimizin yetki dahilinde vermiş oldukları hedef sistemlerinin fiziki sızma testleri, sosyal mühendislik sızma testleri, wifi sızma testleri, network sızma testleri, yazılım ve uygulama sızma testleri, mobil cihaz sızma testleri, server sızma testleri, ağ cihazları sızma testleri ile yetkisiz kişi sistem özellikleri ve verilerine erişebilme, hasar oluşturabilme ihtimalleri o sistem için özel metodoloji ile 32 yıllık tecrübeli takım liderlerimiz öncülüğünde değerlendirilmektedir. ',
            pTwoA: 'Sistemlerin maksimum koruma altında olabilmesi için, bilinçli kullanım oranı arttırılmalı ve yeni yetkili tanımlama, yetki sonlandırma, yeni donanım, yeni yazılım gibi unsurların hareketliliğinden dolayı sızma testleri düzenli aralıklar ile gerçekleştirilmelidir.',
            pTwoB: 'Ayrıca firmamız KVKK uzmanlarının değerlendirmeleri doğrultusunda müşterilerimizin sistemlerinin KVKK veri saklama süreçleri ince detayları ile raporlanmaktadır.',
            pTwoC: 'Sızma testleri 3 yöntem ile gerçekleştirilmektedir;',
            pThreeTitle: 'Siyah Kutu Yöntemi: ',
            pThree: 'Bu yöntem ile gerçekleştirilecek olan testlerde firma ile sözleşme gereği elde edilen bilgiler haricinde bilgi alışverişi sağlanmaz, tam anlamı ile bir saldırgan (hacker) penceresinden erişim ile oluşabilecek olumsuzluklar analiz edilmektedir.',
            pFourTitle: 'Gri Kutu Yöntemi: ',
            pFour: 'Bu yöntem ile gerçekleştirilecek olan testlerde firma sözleşmesi gereği elde edilen bilgiler haricinde kısıtlı bilgi alışverişi yapılarak, sistemin iç yapısında belli yetkilere veya erişime sahip kişilerin kontrol dışı hareketlilik tespiti ile sisteme erişimi olmayan kişilerin sisteme erişimi analiz edilmektedir.',
            pFiveTitle: 'Beyaz Kutu Yöntemi: ',
            pFive: 'Bu yöntem ile gerçekleştirilecek olan testlerde firma ile sözleşme gereği elde edilen bilgiler haricinde sistem ve arkaplan bilgiler paylaşılarak, sistemde işleyişe hakim olan kişilerin yetki dışı alanlara nasıl ulaşabilecekleri veya zarar verebilecekleri analiz edilmektedir. En kapsamlı koordinasyon ve zaman ihtiyacı duyulan bu yöntemdir.',
            pSix: 'Pentest gerçekleştirilecek hedeflerin erişim lokasyanuna göre 2 türü bulunmaktadır.',
            pSevenTitle: 'İç Sızma Testleri:',
            pSeven: 'İlgili hedefin yerel ağları ve sistemleri üzerinden gerçekleştirilecek testlerdir.',
            pEightTitle: 'Dış Sızma Testleri:',
            pEight: 'İlgili hedefin web sitesi, serverları ve uygulamaları üzerinden gerçekleştirilecek testlerdir.',
            titleMet: "SIZMA TEST METODLARI",
            pNineTitle: 'Fiziki Sızma Testi',
            pNine: 'Ekibimiz yetkili yöneticiler ile planlanan zaman aralığında sistem hedeflerine fiziksel olarak yetkisiz şekilde erişimi test edeceği metoddur. Ortamların fiziki sızma girişimlerine korunaksız olması,birçok dijital saldırıya maruz kalmasına sebep olmaktadır. Dünya genelinde standart olan araçlar ile belli planlama dahilinde gerçekleştirilen testlerdir.',
            pTenTitle: 'Sosyal Mühendislik Sızma Testi',
            pTen: 'Ekibimiz yetkili yöneticiler ile planlanan zaman aralığında insan kaynaklı unsurlardan, bilgisayar, insan, oltalama yöntemleri ile yetkili kişilerden bilgi, hareket alanı kazanabilme ihtimallerinin belirlendiği ve raporlandığı testlerdir.',
            pElevenTitle: 'Wifi Sızma Testi',
            pEleven: 'Wifi sistemler hız ve kurulum kolaylıkları gibi nedenlerden dolayı çok tercih edilen, fakat herkesce havada farkedilebilen radyo frekansları ile iletişim sağlayan sistemlerdir. Güvenlik zafiyetlerinin önüne geçebilmek için her 2 senede yeni bir wifi standardı çıkarılsa da bu süreçte kullanıcıların sistemlerin güvenliğini kontrol altında tutması gerekmektedir. Tam bu noktada şirketimiz müşterilerimizin networklerinin merkezinde olan sistemlerden iç networke sızıp kontrolü ele geçirmek isteyenlerin yapacağı hamleleri lisanslı teçhizatlarımızla test ederek güvenlik raporlarlama hizmeti sağlamaktadır.',
            pTwelveTitle: 'Network Sızma Testi ',
            pTwelve: 'Günümüzde her türlü verinin bilişim kanalından geçtiğini düşündüğümüzde, bilişimin ana damarının network sistem olduğu netlik kazanmaktadır. Müşterilerimizin sistemleri için özel planladığımız araçlar ve programlar ile gerçekleştirdiğimiz network sızma testi ile iç lokasyondan belli bilgiler ile yetki dışı erişimi test edilirken, dış lokasyondan ise bir hacker gözü ile testler gerçekleştirilerek en geniş kapsamlı test uygulanmış olacaktır. Network sızma testini müşterilerimizin düzenli aralıklar ile yaptırmalarını önermekteyiz.',
            pThirteenTitle: 'Yazılım ve Uygulama Sızma Testi',
            pThirteen: 'Müşterilerimizin yazılımsal faaliyetler kapsamında elde ettikleri internet siteleri ve uygulamaları (app), dışarıdan network sistemine sızarak, müşteri verileri, mali veriler, kişisel veriler gibi bir çok unsura ulaşılabilecek ve imaj zedelenmesine neden olabilecek sistemlerdir. Sistemleri tasarlayan programcıların güvenlik zafiyetleri konusunda, siber güvenlik uzmanları gibi bir bilgi birikimi olması beklenemez. 32 yıllık deneyim ve uzman kadrosu ile DEF24, sistemlerinizin yazılım ve uygulama sızma testlerini özel metodlar ile gerçekleştirmektedir.',
            pFourteenTitle: 'Mobil Cihaz Sızma Testi',
            pFourteen: 'Hayatımızın hemen hemen her anında karşımıza çıkan ve çok basit kullanıma sahip olan, akıllı telefon, tablet, akıllı saat, akıllı tv, e-okuyucular gibi belirli bir işletim sistemine sahip cihazlar büyük güvenlik zafiyeti oluşturabilmektedirler. En önemli güvenlik önlemi kullanım politikaları olsa da, sistemlerde barınan zafiyetler de göz ardı edilemez. Sistem zafiyetleri ve kurallara uyulmadan kullanımlar iç sistemlerde büyük güvenlik açıklarına neden olmaktadır. Müşterilerimizin maksimum güvenliği için bir branş parametre olarak mobil cihaz sızma testleri gerçekleştirilmektedir.',
            pFifteenTitle: 'Server Sızma Testi',
            pFifteen: 'Günümüzde sistemlerin işleyişinin ve verimin alt yapısını oluşturan server veya benzeri ortak servis hizmeti veren sistemler birçok şirkette bulunmaktadır. Bu serverlar genelde 7/24 çalışıp istenilen kişi sayısına hizmet verebilirler. Serverlarda oluşabilecek güvenlik zafiyetlerinin önemi yüksek önem taşımaktadır. Testleri iç ve dışarıya açık kartlar nedeni ile dış lokasyonlardan gerçekleştirebilmekteyiz.',
            pSixteenTitle: 'Ağ Cihazları Sızma Testi',
            pSixteen: 'Genel olarak iç lokasyondan, yazıcılar, routerlar, firewalllar, switchler gibi ağda bulunan cihazların sistem yöneticileri ile işbirliği sağlanarak gerçekleştirilen test hizmetidir.'
        }
    },
    search: {
        title: "Arama Sonuçları",
        link: "/arama",
        keywords: "arama",
        description: "Arama Sonuçları",
        upperTitle: "Arama Sonuçları",
        noResultMess: "Aradığınız kriterde herhangi bir sonuç bulunamadı."
    },
    reverse: {
        upperTitle: "Araçlar",
        title: "Ters DNS Kaydı",
        link: "/araclar/ters-dns",
        keywords: "reverse hosting, ters ip, ters dns sorgulaması, ters dns kaydı",
        description: "Belirli bir ana bilgisayar adını ve IP'sini tersine çevirmek" +
            " ve aynı sunucuda barındırılan diğer tüm web sitelerini bulmak için sunulan hizmettir.",
        info: "Bu aracı, belirli bir ana bilgisayar adını veya IP'sini tersine çevirmek" +
            " ve aynı sunucuda barındırılan diğer tüm web sitelerini bulmak için " +
            " kullanabilirsiniz. Bu uygulama yalnızca burada sunulan özel bir hizmettir. " +
            " Bu aracı araştırmak istediğiniz adres hakkında daha fazla bilgi " +
            "toplamak amacıyla kullanabilirsiniz.",
        lookupTitle: "Ters DNS Sorgulama Sonuçları",
        placeHolder: "Ip veya Domain Adı",
        maximumLengthMessage: "Bu aramada & sonuç çıkmıştır, fakat hız sorunu nedeniyle 100 tanesi listelenmiştir.",
        resultCountMessage: "Toplam",
        buttonSearch: "Sorgula",
        buttonClear: "Temizle",
        infoMessage: "Son 4 hafta içerisinde & altında barındırılan tüm siteler sunulmuştur, yalnız bazıları IP'lerini değiştirmiş olabilir. Bu liste doğruluğuna göre sıralanmıştır. En iyi ana bilgisayar adları, büyük olasılıkla daha fazla trafiğe sahip olanlardır. Bu siteleri açmak için tıklamanız yeterlidir.",
        messMissing: "Parametreler Eksik",
        messNoFound: "Son 4 haftadır kayıtlarımızı inceledik ve & ile ilgili herhangi bir eşleşme bulamadık. Bu adres herhangi bir web sitesi barındırmıyor olabilir veya bu ip adresinde barındırılan herhangi bir web sitesini taramadık. Sorgunuzu değiştirmek ve başka bir şey aramak isteyebilirsiniz."
    },
    password: {
        upperTitle: "Araçlar",
        title: "Şifre Oluşturucu",
        phraseTitle: "Parola Oluşturucu",
        keywords: "güvenli şifre oluşturma",
        keywordsPassphrase: "güvenli parola oluşturma",
        link: "/araclar/sifre-olusturucu",
        passphraseLink: "/araclar/parola-olusturucu",
        description: "Güvenli şifre üretmenize ve kullanmanıza yardımcı olan ücretsiz hizmet.",
        descriptionPassphrase: "Güvenli parola üretmenize ve kullanmanıza yardımcı olan ücretsiz hizmet.",
        tabOne: "Şifre",
        tabTwo: "Parola",
        params: {
            notePassword: "İyi şifreler en az 12 karakter uzunluğundadır. Eğer yapabilirseniz, " +
                "rastgele sırayla harfler, sayılar ve semboller ekleyin. " +
                "Hesap sağlayıcınızın izin verdiği kadar uzun şifreler oluşturun, " +
                "şifre yöneticisi aldıktan sonra zaten her seferinde yazmanıza gerek kalmayacak.",
            notePassphrase: "Uzun anahtar parolaları hatırlamak (ve yazmak!) kolaydır ve özellikle de bir şifreden daha uzun olduklarında," +
                ' rastgele bir karakter dizisi kadar güvenlidir. "Master" şifreniz için mükemmel bir seçimdir.',
            sliderLabelPassword: "Uzunluk",
            sliderLabelPassphrase: "Kelime Sayısı",
            checkBoxLabelPassword: "Karakter Seçimi",
            checkBoxLabelPassphrase: "Ayrıştırıcı",
            checkBoxSelectionPhraseOne: "Çizgi",
            checkBoxSelectionPhraseTwo: "Boşluk",
            checkBoxSelectionPhraseThree: "Nokta",
        },
        instructionsData: {
            title: "Nasıl çalışır?",
            items: [
                "Parametrenizi belirleyin",
                "Oluşturulan herhangi bir şifreyi seçin, hepsi de işe yarayacaktır",
                "Kopyalayın",
                "Güvenliğinizin keyfini çıkarın"
            ]
        },
        notes: [" Şifreleriniz burada kriptografik olarak güçlü bir rastgele sayı üretecisi tarafından üretilir ve herhangi bir şekilde paylaşılmaz. Sadece",
            " meraklı gözlere ", "dikkat edin ve işiniz bittiğinde tarayıcıyı kapatın."],
        about: {
            title: "İyi Şifre Kuralları",
            subTitle: "İstatistiklerin bir parçası olmayın! Güvenli şifreler, çevrimiçi tehditlere karşı korunmada ilk savunmanızdır.",
            listItems: [
                "Köpeğinizin doğum günü gibi kişisel bilgileri şifre olarak kullanmayın.",
                "Kimlik bilgilerinizi mail veya kısa mesaj aracılığıyla paylaşmayın. Bunlar üçüncü tarafların müdahalesine tamamen açıktır.",
                "Her hesap için farklı bir şifre kullanın. Eğer biri 8 ay önce kullandığınız e-ticaret sitesini hacklerse ve siz de bu burada kullandığınız şifreyi tüm hesaplarınızda kullanıyorsanız, başınız belada demektir. Ve şimdi 308 farklı hesapta şifre değiştirmeniz gerekiyor (Çok kötü bir durum). Evet, her hesap için benzersiz bir şifre belirlemek şifre yöneticisi olmadan neredeyse imkansız...",
                'Şifre yöneticisi kullanın. Birçoğu ücretsiz deneme süresi içeren birçok seçeneğiniz var. Tüm cihazlarınızla senkronize olan bir mobil uygulama alın ve bu şekilde şifrelerinize her zaman erişebileceksiniz. Şifre yöneticinizi güvence altına almak için vatan-bayrak-toprak-millet-asker gibi uzun ama hatırlanması kolay bir "master" anahtar parola kullanın ve bireysel hesaplarınızı uzun rastgele şifrelerle koruyun. Neyse ki, şimdi hemen hemen tüm tarayıcı ve işletim sistemlerinde dahili temel şifre yönetimi bulunmaktadır. Ve biyometrik erişim kontrollerinin artması ile (Face ID ve benzerleri) uzun karmaşık şifreler kullanmamak için gerçekten bir nedeniniz yok.',
                'Güvenlik sorularını rastgele şifreler veya anahtar parolalarla yanıtlayın ve bunları şifre yöneticinizde saklayın. Bu bilgilerin bazıları, örneğin annenizin kızlık soyadı gibi, muhtemelen başkaları tarafından bilinnmektedir. Çoğu şifre yöneticisinde şimdi bu özellik bulunmaktadır.',
                '"Master" şifrenizi periyodik olarak değiştirin. 5 kelimelik bir anahtar parolayı kalıcı olarak ezberlemek sadece birkaç dakikanızı alır.'
            ]
        }
    },
    geoip: {
        upperTitle: "Araçlar",
        title: "IP Lokasyon",
        keywords: "ip, ip sorgulama, ip konum bilgisi",
        link: "/araclar/geoip",
        description: "IP Konum bilgisi, detay bilgisi ve sistem bilgileriniz sunulmaktadır.",
        info: "Verilen lokasyon tahmini bilgidir.",
        lookupTitle: "IP Adresi Sorgulama ve Ayrıntıları",
        placeHolder: "IP veya Domain Adı",
        placeHolderDetail: "Bu alana logları veya birden fazla IP yapıştırabilirsiniz, ayrıca alan adı da desteklenmektedir.",
        buttonSearch: "Sorgula",
        buttonClear: "Temizle",
        locationName: "Konum",
        ipdetailstitle: "IP Detayları",
        systemTitle: "Sisteminizin Bilgileri",
        opTitle: "İşletim Sistemi",
        browser: "Tarayıcı",
        browserLang: "Tarayıcı Dili",
        userA: "Kullanıcı Aracısı",
        accept: "HTTP Ortam Türü(accept)",
        encoding: "HTTP Kodlama(accept-encoding)",
        lang: "HTTP Dil(accept-language)",
        conn: "HTTP Bağlantı(connection)",
        close: "Kapat",
        providerTitle: "Sağlayıcılar"
    },
    gdpr: {
        title: "KVKK",
        keywords: "kvkk, kişisel verilerin korunması kanunu",
        link: "/hizmetler/danismanlik/kvkk",
        description: "Kişisel Verilerin Korunması Kanunu hakkında danışmanlık hizmeti sağlanmaktadır.",
        upperTitle: "Danışmanlık",
        paragrafOne: "Kişisel Verilerin Korunması Kanunu, başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esasları düzenlemek amacını taşımaktadır. Firmamız KVKK’nın 2 temel unsuru olan BİLİŞİM GÜVENLİĞİ ve SÜREÇ UZMANLIĞI konularında uzman ekipleri ile konuya %100 hakimiyet, %100 çözüm sağlamaktadır.",
        specialData: "KVKK danışmanlığı kapsamında;",
        paragrafTwo: "Yükümlülüklerin tanımlanması, bu yükümlülüklere uygun veri kayıt sistemlerinin, sözleşmelerin, ilgili formların gözden geçirilmesi ve uyumlandırılması, kişisel verilerin toplanmasına, işlenmesine, paylaşılmasına ve silinmesine ilişkin politika, prosedür, yönetmeliklerin incelenip gerekli değişikliklerin yapılması ve/veya ihtiyaç duyulan yerlerde yeniden oluşturulması dahil olmak üzere kişisel verilerin korunmasına ilişkin kapsamlı bir hukuki danışmanlık hizmeti,",
        paragrafThree: "Kişisel verileri koruma ve kanuna uyumluluk kapsamında kurumların iş süreçlerini bütün detaylarıyla incelemekte ve süreçlerin ilgili kişisel verileri koruma mevzuatına uyumluluğu için ihtiyaç duyulan eksik veya iyileştirme gereken noktaları belirleyerek bu değişikliklerin iş akışlarını aksatmadan hayata geçirilmesi konusunda danışmanlık hizmeti,",
        paragrafFour: "Veri sınıflandırma, etiketleme, depolama, arşivleme, yedekleme, imha etme, iş sürekliliği, teknik altyapınız için güvenlik yönetimi, uç nokta, e-posta ve mesajlaşma güvenliği çözümleri konularında danışmanlık hizmeti sağlamaktayız.",
        paragrafFive: "Kişisel veri işleyen gerçek kişi ve kurumlar, sahip oldukları verilerin gizliliğini ve bütünlüğünü sağlayarak, erişim haklarını da kontrol altına almasıyla iş süreçlerini sağlıklı yürütebilir ve iş sürekliliğini güvenli bir şekilde sağlayabilirler. Veri güvenliğini tehdit eden başlıca etkenlerin başında olan dış dünyayla bağlantıların ise (gerek sistem gerekse de kullanıcı düzeyinde olabilir) güvenli bir şekilde sağlanması ve kontrol altında tutulabilmesi için ayrıca firmamız tarafından siber güvenlik danışmanlığı hizmeti de sağlanmaktadır."

    },
    iso27001: {
        title: "ISO-27001",
        keywords: "iso 27001 sertifikasyon, bilgi güvenliği, bilgi yönetimi",
        link: "/hizmetler/danismanlik/iso27001",
        description: "ISO 27001 Bilgi Güvenliği Yönetimi ve Standartları hakkında danışmanlık hizmeti verilmektedir.",
        upperTitle: "Danışmanlık",
        documentTitle: "ISO 27001 Bilgi Güvenliği Yönetim Sistemi ( BGYS )",
        paragrafOne: " ISO 27001 Belgesi, firma hacminden bağımsız olarak gizlilik, bütünlük ve erişebilirliklerini sağlamak amacı ile kurdukları bilgi güvenliği yönetim sistemlerini belgelendirmek isteyen veya yükümlü olan tüm müşterilerimizin oluşturdukları sistemlerin bağımsız belgelendirme kuruluşlarınca yapılan denetimleri başarı ile sonuçlandırarak elde edebildikleri bir sertifika programıdır.",
        paragrafTwo: "Bilgi güvenliği, müşterilerimizin güvenlik politikaları, uygulamaları, işleyiş yöntemleri, örgütsel yapıları ile yazılım unsurlarının her birinin sırasıyla ve birbiriyle ilişkili olarak denetlenmesi sonucu sağlanabilmektedir. İlgili sertifika şartlarını sağlayan müşterilerimiz, değerli bilgi varlıklarını yönetme ve koruma potansiyellerini arttırmaktadırlar.",
        paragrafThree: "Özellikle müşterilerimizin kurulum aşamasında dahil olduğumuz danışmanlık faaliyetimiz ile firmanın gerekli dokümantasyon, prosedür, sözleşmeler, sertifikalar   gibi birçok yararlı gereksinimleri kazanmaları ve en doğru adımlar ile  süreçlerinin hızlanması hedeflenmektedir."
    },
    domainConsult: {
        title: "Domain Danışmanlığı",
        keywords: "domain danışmanlığı, wipo, alan adı danışmanlığı",
        link: "/hizmetler/danismanlik/domain",
        description: "Müşterilerimizin sahip oldukları veya olacakları alan adları hakkında engelleme, öngörme ve çözüm önerileri hizmeti verilmektedir. ",
        domainDatas: {
            upperTitle: "Danışmanlık",
            documentTitle: "Domain Danışmanlığı",
            paragrafTitleOne: "Domain danışmanlığı kapsamında;",
            paragrafOne: " firmamızca müşterilerimizin sahip oldukları veya olacakları alan adları için tüm detaylar incelenip raporlanarak projelerinin ilerleyen süreçlerinde alan adlarından kaynaklanacak aksaklıkları engelleme, öngörme ve çözüm önerileri raporlama hizmeti sağlanmaktadır. Kapsam dahilinde firmamiz wipo süreç, alan adı istihbaratı, değer tespiti ve güvenliği konularında 32 yıllık deneyimli kadrosu ile müşterilerimize danışmanlık sağlamaktadır.",
            paragrafTitleTwo: "WIPO Süreç Danışmanlığı, ",
            paragrafTwo: "Müşterilerimizin markalarının alan adlarına sahip olmak (trademark) veya alan adlarının benzeri (typo) uzantısı farklı kişilerde olması durumunda meydana gelen haklarını korumak ve savunmak amaçlı danışmanlık hizmetimizdir. Süreçte müşterilerimizin hukuk danışmanlarına tekniki bilgilerin toplanması, tekniki raporun hazırlanması ve istihbari bilgilerin analizlerini sunabilirken talep edilmesi halinde tüm sürecin takibi firmamızca da yürütülebilmektedir.",
            paragrafTitleThree: "Alan Adı İstihbarat Danışmanlığı (Domain İntelligency), ",
            paragrafThree: "Müşterilerimizin sahip olduğu veya olacakları alan adları için yaptığımız çalışmalar ile kendi himayelerinden önce alan adlarının kimler ve ne amaçla kullanıldığı, ilerleyen süreçlerde bu alan adlarının sizlere problem oluşturup oluşturmayacağı konularında raporlama hizmetini gerçekleştirmekteyiz. 2018 yılından sonra GDPR ve KVKK yasaları neticesinde almak istediğiniz alan adlarının sahiplik bilgilerine ulaşabilmek sizler için zorlaşmış iken bu konuda da müşterilerimize yardımcı olmaktayız.",
            paragrafTitleFour: "Alan Adı Değer Tespiti Danışmanlığı, ",
            paragrafFour: "Müşterilerimizin sahip olduğu veya olacakları alan adlarının tüm dünya konjektöründe değerinin tespit edilmesi ve temel sebepleri ile raporlanması hizmeti sağlanmaktadır. Tespit çalışmamız da kullandığımız 10 temel sebep, ilgili alan adının hem değerinde hem de hızlı şekilde alınmasına veya satılmasına yüksek fayda sağlamaktadır",
            paragrafTitleFive: "Alan Adı Güvenliği Danışmanlığı, ",
            paragrafFive: "Müşterilerimize sahip olduğu veya olacakları alan adlarının edinimi, yaşayabileceği muhtemel sorunlar, alan adı üzerinden kişisel verilerinizin çalınması ve alan adının güvenliği gibi hususlarda detaylı rapor hazırlanarak danışmanlık hizmeti sağlanmaktadır."
        }
    },
    cyberConsult: {
        title: "Siber Güvenlik Danışmanlığı",
        keywords: "siber güvenlik danışmanlığı, network sistemleri, yazılım mimarisi, bulut güvenliği, cloud güvenliği, güvenlik politikaları",
        link: "/hizmetler/danismanlik/siber",
        description: "Network sistemleri, yazılım siber güvenlik mimarisi, bulut güvenliği, güvenlik politikaları hakkında kapsamlı hizmet verilmektedir.",
        cyberConsultDatas: {
            upperTitle: "Danışmanlık",
            documentTitle: "Siber Güvenlik Danışmanlığı",
            contentTitle: 'Danışmanlıklarımız',
            pOne: "Firmamız müşterilerimizin mevcut ve özellikle yeni proje başlangıçlarında bilişim sistemlerinin bütünleşik siber güvenlik önlemleri altında gerçekleştirebilmesi adına uzman ekiplerimizce danışmanlık hizmeti sağlamaktadır.",
            pTwo: "Çalışma kapsamında müşterilerimizin network sistemlerinin, yazılım siber güvenlik mimarisinin, bulut güvenliğini, fiziki ortam ve şirket güvenlik politikalarının oluşturulması, geliştirilmesi ve denetimi sağlanmaktadır.",
            pTitleThree: "Network Sistemlerin Güvenlik Danışmanlığı",
            pThree: "Network sistemler kurulum sürecinde adminlerce yapısal olarak tasarlanmalıyken, dış ve iç lokasyondan oluşabilecek güvenlik zafiyetleri de siber güvenlik uzmanlarınca tasarlanmalı ve maksimum koruma sağlanmalıdır. Mevcut network sistemlerinde siber güvenlik açısından düzenlenmesi mümkündür fakat yeni kurulum planlanmasına nazaran maliyetli ve süreçli bir işlemdir. DEF24 olarak 32 yıllık tecrübe ve uzman kadromuz ile sistem adminleriniz ile aynı doğrultuda sizin güvenli sistemleriniz için hazırız.",
            pTitleFour: "Yazılım Mimari Güvenlik Danışmanlığı",
            pFour: "Firmamiz müşterilerimizin yazılım mimari süreçlerinde hazırlanmakta olan sistemlerin siber güvenlik penceresinden kontrollerini sağlayacak bir takım arkadaşı olarak, geliştirilmekte olan projenin tüm güvenlik çerçevesini oluşturmakta ve hamlelerin neden - sonuç ikilemini raporlama hizmeti sağlanmaktadır. Herşeyin dijital olduğu bir ortamda çoğu zafiyetler uzun yıllar farkına varılmadan devam eder ve zararı ortaya çıktığında müşterilerimizin imaj ve varlık kaybına neden olmaktadır. Sistem tasarımcılarınız sizin için en yüksek performans ile emek harcar iken firmamız geri dönülmez problemleri egale edebilmek için yazılımcımlarınıza danışmanlık hizmeti sağlamaktadır.",
            pTitleFive: "Bulut Güvenliği Danışmanlığı",
            pFive: "Genellikle istemsizce de olsa sistemlerimizin büyük çoğunluğu elastiki yapı, teknolojik altyapı, verimlilik, hız ve maliyet nedenleri ile bulut (cloud) sistemlerinde barınmaktadır. Bulut sistemleri birçok nedenden dolayı gerekli ve zorunlu hale gelmiştir.",
            pSix: "Bulut sistemleri büyük avantajları nedeni ile tercih edilirken,bulut sağlayıcılarının sistemlerin her sektöre uygun olabilmesi için genel bir çerçeve kullanması nedeniyle yüzlerce konfigürasyon ve servis kombinasyonu etmenlerinden dolayı dikkat edilmesi gereken en önemli noktası sistemlerin güvenlikleridir.",
            pSeven: "Unutmayın ki, bulut sistemlerine geçiş büyük bir karardır. Sistem sağlayıcı firmaların, sistem kullanıcıları için özel alt yapı hazırlaması sonucu kullanıcının bulut sisteme yerleşmesi ve kök salması sağlanmaktadır. Sisteme dahil olmak kolay, sistemden uzaklaşmak ancak aynı alt yapı ile mümkündür. DEF24 olarak 32 yıllık sektör deneyimimiz ve uzman kadromuz ile en doğru adımları atmanızı ve maksimum korumayı sağlamaktayız.",
            pTitleEight: "Güvenlik Politikaları Danışmanlığı",
            pEight: "Şirketimiz bilişim sektöründeki uzun deneyimleri sonucu en önemli güvenlik zafiyet sebebinin insan faktörü olduğunu, eğitim ve teknolojiyi kullanım politikaları ile bu problemin önüne geçilebileceğini bilmektedir. Çalışanlarınıza sektörlere göre değişiklik göstermekle birlikte hassas konularda eylem alanı sınırları çizilmemesi halinde, kendilerince en doğru bildiklerini masumani olarak uygulayacaklardır.",
            pNine: "İş akdi başlama ve sonlanmadan, şifre politikalarına kadar birçok kritik noktada deneyimli kadromuz ile müşterilerimizin bilişim teknolojilerinin tüm kullanımlarının şirket politikaları oluşturma ve denetim sağlama hizmeti sağlamaktayız.",
            pTwelve: "DEF24 ile dogru yolda beraber yürüyün."
        }
    },
    audit: {
        title: "Zaafiyet Tarama(Audit)",
        keywords: "zaafiyet tarama ve yönetimi audit",
        link: "/hizmetler/siber/audit",
        description: "Müşterilerimizin sistemlerine özgü ve tam kapsamlı hata arama ve tarama faaliyetleri sağlamaktayız.",
        datasAudit: {
            upperTitle: 'Siber Güvenlik',
            documentTitle: "Zaafiyet Tarama ve Yönetimi (AUDIT)",
            pOne: 'Zafiyet tarama ve yönetimi; sistemlerin donanım, yazılım, versiyon, network mimari, fiziksel etmenler gibi unsurların, sistem yöneticileri dışında bir dış göz olarak analiz edilip neden sonuç ilişkilerinin raporlanması işlemidir.',
            pTwo: 'Firmamız 32 yıllık sektör deneyimi neticesinde siber güvenliğin unsurlarının çok geniş kapsamlı olduğunun, sistem yöneticilerinin, adminlerin birçok zafiyet konularına hakim olabileceklerini fakat güvenlik sektörünün bir kedi fare oyunu olarak tabir edilmesinden dolayı, güvenliğin tam kontrol altında olabilmesi için en yüksek perdeden en geniş pencereden tarama gerçekleşmelidir.',
            pThree: 'Müşterilerimizin sistemlerine özgü ve tam kapsamlı yapılacak olan, hata arama ve tarama faaliyetleri sonucu sunacağımız raporlarda; hata, hataların kaynakları, hataların yol açabilecekleri mağduriyetler ve hataların giderilmesi için gerekli olan çalışmalar yer almaktadır. ',
            pFour: 'Daha güvenli bir sistem için yöneticilerin rapor doğrultusunda gerekli eksikliklerin giderildiğini belirtmesi durumunda, sisteme ortak karar verilen metodla sızma testi gerçekleştirilerek önlemlerin yeterlilik seviyeleri analiz edilmesi önerilir.'

        }
    },
    osint: {
        title: "Açık Kaynak Bilgi İstihbaratı (OSINT)",
        keywords: "açık kaynak bilgi istihbaratı, osint, sosyal medya araştırması",
        link: "/hizmetler/siber/osint",
        description: "Açık kaynak bilgilerinin, hacking, doxing(dokslama), sosyal mühendislik gibi faaliyetler sonucu oluşabilecek problemlerden korumaktadır.",
        datasOsint: {
            upperTitle: 'Siber Güvenlik',
            documentTitle: 'Açık Kaynak Bilgi İstihbaratı (OSINT)',
            pOne: 'Açık kaynak bilgi istihbaratı; önlem alınmamış ve herkes tarafından ulaşılabilir olan bilgilerin, yetkisiz kişiler (rakipler/ kötü niyetli kişiler) tarafından elde edebilmesi durumunun analizleri gerçekleştirilerek neden sonuç ilişkilerinin raporlanması işlemidir.',
            pTwo: '32 Yıllık tecrübe ve bilgi birikimi ile DEF24, müşterilerimizin internet sistemlerinde yer alan suç unsuru olmaksızın erişilebilecek (AÇIK KAYNAK) bilgilerinin, hacking, doxing(dokslama), sosyal mühendislik, rakip analizi, yer belirleme ve reklam analizleri gibi faaliyetlerde kullanılması sonucu oluşabilecek problemlerden korumaktadır.',
            pFive: 'Açık kaynak bilgi istihbaratına Facebook, Twitter, Linkedin, Google gibi bilindik ağlar üzerinden ulaşmak yeterli değildir. Dünya üzerinde binlerce şirket, bilgileri değişik kanallardan iz sürmekte ve kayıt altına almaktadır. Firmamız müşterilerimizin internet ortamındaki profilini raporlar, istediğiniz bilgi ve belgelerin erişime engellenmesine yardımcı olarak kişisel ve tüzel kimliklerinizin koruma oranını arttırır.'

        }
    },
    dataSecurity: {
        title: "Veri Güvenliği",
        keywords: "veri güvenliği, data security, kvkk, veri hırsızlığı",
        link: "/hizmetler/bilisim/veri-guvenligi",
        description: "Firmanızda gerçekleşmiş zafiyetlerin tespiti, gerçekleşme anının tespiti ve engellenmesi ayrıca bu işlemlerin kayıt altına alınması sağlanmaktadır.",
        datasSecurity: {
            upperTitle: 'Bilişim Güvenliği',
            documentTitle: 'Veri Güvenliği',
            pOne: 'Veri güvenliği; müşterilerimizin özel ve gizli nitelikli verilerinin şirket dışına çıkması ve yetkisiz kişilerce erişilmiş olması durumunun tespiti ve engellenmesi hizmetidir.',
            pTwo: 'Verinin önemi arttıkça sorumluluk artmaktadır. Ülkemizde KVKK sürecinde kurum ve kuruluşlarca işlenen kişisel verilerin saklanması, işlenmesi gibi hususların önemi net belirtilmekte ve ağır yaptırımlar uygulanmaktadır. Şirketiniz personellerinin aylarca çalışarak elde ettiği projelerin 1 diske aktarılarak kopyalanması birkaç dakika sürecektir. Olumsuz olaylar ile karşılaşmamak için firmamızca sağlanacak hizmetlerimiz ile verileriniz güvende kalacaktır.',
            pThree: 'Müşterilerimizin talepleri doğrultusunda, kontrolleri altındaki sistemlerde özel ve gizli nitelikli verilerinin güvenliği uzman ekibimiz ve firmamızca uygun metotlar ile sağlamaktadır. Çalışmalarımız neticesinde özellikle endüstride sıklıkla karşılaştığımız özel bir çalışma için tasarlanmış projenin çalınması, özel bir iş için hazırlanmış proje teklif ve detay dosyalarının rakiplere ulaşması, KVKK kapsamında taraflardan alınmış kişisel veriler, müşteri verileri, finans verileri, özlük dosyaları, projeler, ihaleler, şirket detay bilgileri gibi verilerin kontrolünüz dışındaki kişilere ulaşması engellenmektedir.',
            pFour: 'Firmanıza özel sağlayacağımız yazılım ve donanımlar ile bilgi trafiği, kesinliklikle firmanız bünyesinde kalarak kurmuş olduğumuz sistem sayesinde, firmanızda gerçekleşmiş zafiyetlerin tespiti, gerçekleşme anının tespiti ve engellenmesi ayrıca bu işlemlerin kayıt altına alınması sağlanmaktadır.',

        }
    },
    efficienyAnalysis: {
        title: "Verimlilik Analizi",
        keywords: "verimlilik analizi",
        link: "/hizmetler/bilisim/verimlilik-analizi",
        description: "Sistem kullanıcılarının ve şirket çalışanlarının işlerindeki zamanlarını verimli geçirebilmesi için gerçekleştirilen raporlama hizmetidir.",
        efficienyAnalysisDatas: {
            upperTitle: "Bilişim Güvenliği",
            documentTitle: "Verimlilik Analizi",
            pOne: 'Verimlilik analizi; sistem kullanıcılarının, şirket çalışanlarının işleri için zamanlarını verimli geçirebilme raporlama hizmetidir.',
            pTwo: 'Firmamız bilişim sektöründeki deneyimi ve profesyonel yaklaşımı ile müşterilerimizin sistem kullanıcılarının, çalışanlarının, firmanıza özel sağlayacağımız yazılım ve donanımlar ile bilgi trafiği kesinlikle firmanız bünyesinde kalarak kurmuş olduğumuz sistem sayesinde, verimlilik analizleri raporlanmaktadır. ',

        }
    },
    reverseEngineering: {
        title: "Tersine Mühendislik",
        keywords: "tersine mühendislik, reverse engineering",
        link: "/hizmetler/bilisim/tersine-muhendislik",
        description: "Casus materyallerinin incelenmesi gibi hem aktif, hemde pasif yöntemlerle açık kaynak ve derlenmiş dijital materyalleri incelenmektedir.",
        reverseEngineeringDatas: {
            upperTitle: "Bilişim Güvenliği",
            documentTitle: "Tersine Mühendislik",
            pOne: 'Tersine mühendislik; bir sistemin yapısının, işlevinin veya çalışmasının çıkarımcı bir akıl yürütme analiziyle keşfedilmesi, var olan sistemin en ince detaylarının analizleri ile tasarım başlangıcındaki parametrelere ulaşılma işlemidir.',
            pTwo: 'Firmamız bilişim sektöründeki deneyimi ve profesyonel yaklaşımı ile müşterilerimizin sistemlerinde; kötü amaçlı yazılım, fidye yazılım, veri hırsızlığı yazılım, mevcut uygulamaların güvenliğinin teyiti, şirket bilgisayarlarında bulunan casus materyallerinin orjinlik ve tehlike düzeyinin incelenmesi gibi hem aktif, hemde pasif yöntemlerle açık kaynak ve derlenmiş dijital materyalleri inceleyerek tespit ve çözüm raporları sunmaktadır.'

        }
    },
    customerTrainee: {
        title: "Siber Güvenlik Eğitimleri",
        keywords: "siber güvenlik eğitimleri",
        link: "/hizmetler/egitim",
        description: "Bilişim dünyasında insan faktörü en önemli husus olduğu için son kullanıcı ve yöneticilere detaylı eğitim verilmektedir.",
        datasTrainee: {
            upperTitle: 'Eğitim',
            documentTitle: 'Siber Güvenlik Eğitimleri',
            pOne: "32 yıllık bilişim sektöründeki deneyimimiz ve bilimsel araştırmalar göstermekteki; bilişim sektöründe gerçekleşen saldırıların %92’si insan faktöründen dolayı başarıya ulaşmaktadır.",
            pTwo: "İstediğimiz kadar son teknoloji sistemler ile çalışmalarımızı ilerletelim, yanlış, korumasız, bilinçsiz teknoloji kullanımı kötü sonuçlar ortaya çıkarmaya mecburdur. Bu kapsamda bilişim dünyasında  insan faktörünün sistem kullanıcıları ve sistem yöneticileri olarak iki (2) başlıkta ele alınması gerektiğini ve her iki gruba da  farklı sorumluluklar ve yükümlülükler yüklendiğini bilmekteyiz. Şirketimiz, üzerinde hissettiği büyük sorumluluk nedeniyle bireylerin farkındalığının artması ve bilinçli kullanıcı olabilmesi amacıyla eğitim çalışmalarına önem vermektedir.",
            pTitleThree: 'Sistem Kullanıcıları Eğitimi',
            pThree: "Günümüzde son kullanıcıların antivirus ve/veya firewall dahi kullanarak saldırganlara karşı savunmasız olduğu, küçük detaylara dikkat ederek kendini ve kullanıcısı olduğu sistemi daha güvende tutabilecek iken; sosyal mühendislik, şifre oluşturma, basit KVKK kuralları ve şifre barındırma gibi konulara hakim olmadıkları için başarısız olduklarını gözlemlemekteyiz. Sistemlerini güvende tutabilme, kendinin ve şirketlerin verilerini  güvenle  koruyabilmeleri için yapılması gerekenleri, haklarını farkındalık çalışmaları ve eğitimler ile kullanıcılara aktarmaktayız.",
            pFour: "Tüm ilkokul ortaokul ve lise düzeylerinde *Güvenli İnternetim, Güvenli Bilişim, Güvenli Verim * konulu farkındalık eğitimleri vermekteyiz . ( Farkındalık eğitim talebi oluşturmak için ...) Kurum kuruluş ve toplulukların talebi üzerine * Güvenli Verim, Güvenli Bilişim * başlıklı farkındalık eğitimleri ile kişilerin kendileri koruyabilmeleri için bilgileri ve KVKK kapsamında verisi işlenen kurumdaki hakları, işleyişi ve sosyal mühendislik çalışmaları ile yapılan çalışmaları aktarmaktayız.",
            pTitleFive: 'Sistem Yöneticileri ve Sorumluları Eğitimi',
            pFive: "Sistem yöneticileri ve sorumlularına yönelik eğitimlerimiz de göz önünde bulundurduğumuz en önemli kriterimiz, bir departman müdürünün hukuk, bilişim, network, yazılım konularında geniş bilgi ve deneyime sahip olamayacağı gibi bir sistem yöneticisinin de hukuk, yazılım, siber güvenlik konularında geniş bilgi ve deneyime sahip olamayacağı aşikardır.",
            pSix: "DEF24 olarak bu  bilgilerin büyük ekipler ile elde edilebileceğinin bilgilendirilmesi, KVKK ve Bilişim güvenliği için  gerekli şirket politikalarının oluşturulması, şifre politikaları, sosyal mühendislik, güvenlik önlemleri gibi hususlarda işleyiş ve kontrol noktasında bilinçlendirilmesi, yükümlülerin bilgilerinin artırılması, eksik noktalarının analiz edilip hataların giderilmesi ve sahip oldukları sistemlerin maksimum savunmaya ulaşabilmesi amacıyla  hizmet sağlamaktayız."
        }
    },
    tscm: {
        title: "Ortam Güvenliği",
        keywords: "ortam güvenliği, tscm, böcek, dinleme, ses kayıt, video kayıt",
        link: "/hizmetler/ortam-guvenligi",
        description: "Makam, ofis ve ulaşım araçları vb tüm mekanların kontrolleri dışında dinlenmesi ve izlenmesinin tespiti ve engellenme hizmeti verilmektedir. ",
        datasTscm: {
            upperTitle: 'Ortam Güvenliği',
            documentTitle: "Ortam Güvenliği (TSCM)",
            pOne: 'Firmamızca müşterilerimizin kontrolünde bulunan makamlar, ofisler, toplantı salonları, gayrimenkuller, kişisel ulaşım araçları gibi tüm mekanlarının, kontrolleri dışında dinlenmesi ve izlenmesini faaliyetlerinin tespiti ve engellenmesi, ayrıca oluşturmak istedikleri yalıtım odalarının testlerini gerçekleştirme hizmeti verilmektedir.',
            pTwo: "Gelişen teknoloji ve teknolojinin kullanım bilgisi arttıkça  güvenlik kameraları, laptoplar, akıllı saatler, telefonlar, routerlar gibi gündelik bilişim ürünleri gizli görüntüleme ve dinleme araçları olabilmekte, sadece radyo frekansı analizi ile TSCM faaliyeti gerçekleştirmeyi zorlaştırmaktadır. Bu nedenle en net çözüm için radyo frekansı ve bilişim güvenliği analizleri ile çalışma gerçekleştirilmelidir. Karşımıza çıkan bilişim güvenliği zafiyetlerinin bir türü olan Ortam Güvenliği (TSCM) iş hayatı, sosyal hayat, özel hayat gibi birçok unsuru olumsuz etkileyecek bilgilerinin kontrolünüz dışında anlık veya kısa geçmiş zamanlı dinleme ve görüntüleme işlemidir. Faaliyet kapsamında elde edilen bilgiler kısa sürede kullanılabildiği gibi saklanarak hayatınızın kritik anında karşınıza getirilmek istenmektedir.",
            pThree: 'Müşterilerimizce talep edilen ortamın Güvenliğini, radyo frekans analizi, dinleme ve izleme cihazları tespiti, telefonlar, boatlar, prizler ve kablolarda olağan  dışı güç analizlerini ve Wi-fi, LAN,  WLAN ağların analizlerini, manuel tarama, lisanslı son teknoloji RF tespit ürünlerimiz ve termal görüntüleme cihazlarımız ile gerçekleştirmekteyiz.'

        }
    },
    privacy: {
        title: "Gizlilik Poliçesi",
        keywords: "gizlilik poliçesi, kvkk, privacy policy, çerez politikası, cookie",
        link: "/gizlilik",
        description: "DEF24 Gizlilik Poliçesi ve Çerez Politikası",
        datasPolicy: {
            title: 'Gizlilik Poliçesi',
            upperTitle: "DEF24 BİLİŞİM VE SAVUNMA HİZMETLERİ WEB SİTESİ GİZLİLİK VE ÇEREZ POLİTİKASI",
            pOne: 'DEF24 Bilişim ve Savunma Hizmetleri tarafından işletilen',
            pOneLink: 'www.def24.com',
            spanOne: 'web sitesini ziyaret edenlerin',
            spanBoldOne: '(“ziyaretçi”) ',
            spanTwo: 'kişisel verilerini 6698 sayılı Kişisel Verilerin Korunması Kanunu KVKK ',
            spanBoldTwo: '(“Kanun”) ',
            spanThree: 've Avrupa Birliği Veri Koruma Tüzüğü GDPR uyarınca işlemekte ve gizliliğini korumaktayız. Bu Web Sitesi Gizlilik ve Çerez Politikası ',
            spanBoldFour: '(“Politika”) ',
            spanFour: 'ile ziyaretçilerin kişisel verilerinin işlenmesi, çerez politikası ve internet sitesi gizlilik ilkeleri belirlenmektedir.',
            pTwo: 'Kişisel verilerin toplanması, kullanımı ve işlenmesi;',
            pThree: 'DEF24, ilgili kişisel verileri bu web sitesini kullanıcılara sunmak ve bu sitenin uygun şekilde çalıştığının ve gerekli ölçüde güvenliğin sağlandığının temin edilmesi doğrultusunda kullanır. Bu kapsam dışında kalan ve veriler üzerinde gerçekleştirilen her işlem, diğer yasal yükümlülükler, izinler, DEF24’ün meşru menfaatinin bulunduğu haller zemininde ya da ilgili kullanıcının DEF24’e verdiği açık rıza çerçevesinde gerçekleşmektedir.',
            pFour: 'Çerezler',
            spanBoldFour: '(cookies), ',
            spanFive: 'küçük bilgileri saklayan küçük metin dosyalarıdır. Çerezler, ziyaret ettiğiniz internet siteleri tarafından, tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanır. İnternet sitesi tarayıcınıza yüklendiğinde, çerezler cihazınızda saklanır. Çerezler, internet sitesinin düzgün çalışmasını, daha güvenli hale getirilmesini, daha iyi kullanıcı deneyimi sunmasını sağlar. Oturum ve yerel depolama alanları da çerezlerle aynı amaç için kullanılır. İnternet sitemizde çerez bulunmamakta, oturum ve yerel depolama alanları çalışmaktadır.',
            pFive: 'Web sitemizin ziyaretçiler tarafından en verimli şekilde faydalanılması için çerezler kullanılmaktadır. Çerezler tercih edilmemesi halinde tarayıcı ayarlarından silinebilir ya da engellenebilir. Ancak bu web sitemizin performansını olumsuz etkileyebilir. Ziyaretçi tarayıcıdan çerez ayarlarını değiştirmediği sürece bu sitede çerez kullanımını kabul ettiği varsayılır.',
            pSixTitle: 'Kişisel Verilerin İşlenme Amacı',
            pSix: 'Web sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz aşağıda sıralanan amaçlarla DEF24 Bilişim ve Savunma Hizmetleri tarafından Kanun’un 5. ve 6. maddelerine uygun olarak işlenmektedir:',
            pSevenList: [
                'DEF24 Bilişim ve Savunma Hizmetleri tarafından yürütülen ticari faaliyetlerin yürütülmesi için gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin gerçekleştirilmesi,',
                'DEF24 Bilişim ve Savunma Hizmetleri tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların yapılması ve ilgili iş süreçlerinin gerçekleştirilmesi,',
                'DEF24 Bilişim ve Savunma Hizmetleri tarafından müşterilerimizin iletişim, talep formuna oluşturdukları bilgiler, taleplerine karşılık vermek için kullanılması ve yasal süreç boyunca müşteri kayıt sisteminde tutulması,',
                'DEF24 Bilişim ve Savunma Hizmetleri tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılmasıdır.'
            ],
            pSevenTitle: 'Kişisel Verilerin Aktarıldığı Taraflar ve Aktarım Amacı',
            pSeven: 'Web sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz, kişisel verilerinizin işlenme amaçları doğrultusunda, iş ortaklarımıza, tedarikçilerimize kanunen yetkili kamu kurumlarına ve özel kişilere Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında aktarılabilmektedir.',
            pEightTitle: 'Kişisel Verilerin Toplanma Yöntemi',
            pEight: 'Çerezler, ziyaret edilen internet siteleri tarafından tarayıcılar aracılığıyla cihaza veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Web sitemiz ziyaret edildiğinde, ziyaretçinin izniyle web sitemize ek olarak google.com, facebook.com, twitter.com, instagram.com, linkedin.com, youtube.com alanlarına da çerezler uygulanmaktadır.',
            pNineTitle: 'Çerezleri Kullanım Amacı',
            pNine: 'Web sitemiz birinci ve üçüncü taraf çerezleri kullanır. Birinci taraf çerezleri çoğunlukla web sitesinin doğru şekilde çalışması için gereklidir, kişisel verilerinizi tutmazlar. Üçüncü taraf çerezleri, web sitemizin performansını, etkileşimini, güvenliğini, reklamları ve sonucunda daha iyi bir hizmet sunmak için kullanılır. Kullanıcı deneyimi ve web sitemizle gelecekteki etkileşimleri hızlandırmaya yardımcı olur. Bu kapsamda çerezler;',
            pTenTitle: 'İstatistikler:',
            pTen: 'Bu çerezler, web sitesine gelen ziyaretçi sayısı, benzersiz ziyaretçi sayısı, web sitesinin hangi sayfalarının ziyaret edildiği, ziyaretin kaynağı vb. bilgileri depolar. Bu veriler, web sitesinin ne kadar iyi performans gösterdiğini ve analiz etmemize yardımcı olur.',
            pElevenTitle: 'Pazarlama:',
            pEleven: 'Web sitemiz reklam veriyor. Bu çerezler, size gösterdiğimiz reklamları sizin için anlamlı olacak şekilde kişiselleştirmek için kullanılır. Bu çerezler ayrıca bu reklam kampanyalarının verimliliğini takip etmemize yardımcı olur. Bu çerezlerde depolanan bilgiler, üçüncü taraf reklam sağlayıcıları tarafından size tarayıcıdaki diğer web sitelerinde reklam göstermek için de kullanılabilir.',
            pTwelveTitle: 'İşlevsel',
            pTwelve: 'Bunlar, web sitemizdeki bazı önemli olmayan işlevlere yardımcı olan çerezlerdir. Bu işlevler arasında videolar gibi içerik yerleştirme veya web sitesindeki içerikleri sosyal medya platformlarında paylaşma yer alır.',
            pThirteenTitle: 'Tercihler',
            pThirteen: 'Bu çerezler ayarlarınızı kaydetmemize ve dil tercihleri gibi tarama tercihlerinizi belirlememize yardımcı olur, böylece web sitesine gelecekteki ziyaretlerinizde daha iyi ve verimli bir deneyime sahip olursunuz.',
            tableTitle: 'Teknik olarak web sitemizde kullanılan çerez türleri aşağıdaki tabloda gösterilmektedir.',
            tableDatas: [
                { itemOne: 'Oturum Çerezleri (Session Cookies)', itemTwo: 'Oturum çerezleri ziyaretçilerimizin web sitemizi ziyaretleri süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici çerezlerdir. Amacı ziyaretiniz süresince İnternet Sitesinin düzgün bir biçimde çalışmasının teminini sağlamaktır.' },
                { itemOne: 'Kalıcı Çerezler (Persistent Cookies)', itemTwo: 'Kalıcı çerezler web sitesinin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan çerez türleridir. Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır.' },
                { itemOne: 'Teknik Çerezler (Technical Cookies)', itemTwo: 'Teknik çerezler ile web sitesinin çalışması sağlanmakta, internet sitesinin çalışmayan sayfaları ve alanları tespit edilmektedir.' },
                { itemOne: 'Otantikasyon Çerezleri (Authentication Cookies)', itemTwo: 'Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek, her sayfada şifresini yeniden girmesi önlenir.' },
                { itemOne: 'Flash Çerezleri (Flash Cookies)', itemTwo: 'İnternet sitesinde yer alan görüntü veya ses içeriklerini etkinleştirmek için kullanılan çerez türleridir.' },
                { itemOne: 'Kişiselleştirme Çerezleri (Customization Cookies)', itemTwo: 'Kullanıcıların dil gibi tercihlerini farklı internet sitesinin farklı sayfalarını ziyarette de hatırlamak için kullanılan çerezlerdir.' },
                { itemOne: 'Analitik Çerezler (Analytical Cookies)', itemTwo: 'Web sitesini ziyaret edenlerin sayıları, görüntülenen sayfaların tespiti, ziyaret saatleri, sayfaları kaydırma hareketleri gibi analitik sonuçları izleyen çerezlerdir.' },
            ],
            pFourtienTitle: 'Web sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:',
            pFourtienList: [
                'İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan hizmetleri geliştirmek,',
                'İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek,',
                'İnternet Sitesinin, Sizin ve DEF24 Bilişim ve Savunma Hizmetlerinin hukuki ve ticari güvenliğinin teminini sağlamak.'
            ],
            pFifteenTitle: 'Çerez Tercihlerini Kontrol Etme',
            pFifteen: 'Farklı tarayıcılar web siteleri tarafından kullanılan çerezleri engellemek ve silmek için farklı yöntemler sunar. Çerezleri engellemek / silmek için tarayıcı ayarları değiştirilmelidir. Tanımlama bilgilerinin nasıl yönetileceği ve silineceği hakkında daha fazla bilgi edinmek için',
            pFifteenLink: 'www.allaboutcookies.org',
            pSpanFifteen: 'adresini ziyaret edilebilir. Ziyaretçi, tarayıcı ayarlarını değiştirerek çerezlere ilişkin tercihlerini kişiselleştirme imkânına sahiptir.',
            pSixteenTitle: 'Politika’nın Yürürlüğü',
            pSixteen: 'Bu Politika yayınlandığı tarihte yürürlüğe girer. Politika’nın tümünün veya belirli maddelerinin yenilenmesi halinde Politika’nın yürürlük tarihi revize edilir.',
            pSeventeen: 'DEF24 Bilişim ve Savunma Hizmetleri Kişisel Verilerin Korunması ve İşlenmesi Politikası, Kişisel Veri Saklama ve İmha Politikası, Başvuru Formu için',
            pLinkBelow: 'www.def24.com',
            pLast: 'adresinden ulaşabilirsiniz.'
        }

    },
    terms_conditions: {
        title: "Kullanım Koşulları ve Üyelik Sözleşmesi",
        keywords: "kullanım koşulları ve üyelik sözleşmesi",
        link: "/kullanim-kosullari",
        description: "DEF24 Kullanım Koşulları ve Üyelik Sözleşmesi",
        datas: {
            documentTitle: 'Kullanım Koşulları ve Üyelik Sözleşmesi',
            pOne: "Bu internet sitesini (def24.com) (Kısaca " + '"SİTE"' + "olarak anılacaktır) kullanmak için lütfen aşağıda yazılı koşulları okuyunuz. Bu SİTE'de sunulan hizmetlerden yararlananlar veya herhangi bir şekilde SİTE'ye erişim sağlayan her gerçek ve tüzel kişi aşağıdaki kullanım koşullarını kabul etmiş sayılmaktadır.",
            pTwoTitle: 'KULLANIM KOŞULLARI',
            pTwo: "Bu SİTE'de sunulan hizmetler 815 Ponce De Leon Blvd Second Floor, 33134 Coral Gables, Florida, USA adresinde bulunan Def24 Inc (Bundan böyle kısaca" + '"DEF24"' + "olarak anılacaktır.) tarafından sağlanmaktadır ve SİTE'nin kanuni sahibi DEF24 olup, SİTE üzerinde her türlü kullanım ve tasarruf yetkisi DEF24'ya aittir.",
            pThree: "İşbu kullanım koşulları, DEF24 tarafından gerektiğinde değiştirilebilir. Değişiklik olması halinde, bunlar SİTE'de yayınlanacak ve aynı tarihten itibaren geçerli olacaktır.",
            pFour: "SİTE hizmetlerinden faydalanan ve SİTE'ye erişim sağlayan her gerçek ve tüzel kişi, DEF24 tarafından işbu kullanım koşulları hükümlerinde yapılan her değişikliği, önceden kabul etmiş sayılmaktadır.",
            pFive: "DEF24, bu SİTE'de yer alan veya alacak olan bilgileri, formları ve içeriği dilediği zaman değiştirme hakkını saklı tutmaktadır.",
            pSixTitle: "SÖZLEŞME TANIMLARI",
            pSeven: "SİTE: DEF24 tarafından belirlenen çerçeve içerisinde çeşitli hizmetlerin ve içeriklerin sunulduğu çevrimiçi ortamdan erişimi mümkün olan internet sitesidir.",
            pEight: "ÜYE: DEF24'dan ürün alımı yapmak üzere faydalanmak isteyen, seçmiş olduğu üyelik formunu eksiksiz doldurarak, DEF24 tarafından üyelikleri onaylanarak, kabul edilen her gerçek kimlik bilgileri ile SİTE'deki ilgili üyelik formunu eksiksiz doldurarak üye olabilirler.",
            pNine: "KULLANICI: DEF24 internet sitesini alışveriş yaparak veya alışveriş yapmaksızın ziyaret eden kişidir.",
            pTen: "LİNK: SİTE üzerinden bir başka internet sitesine, dosyalara, içeriğe veya başka bir internet sitesinden SİTE'ye dosyalara ve içeriğe erişimi mümkün kılan bağlantıdır.",
            pELeven: "İÇERİK: SİTE'de veya herhangi bir internet sitesinde yayınlanan veya erişimi mümkün olan her türlü bilgi, dosya, resim, program, rakam, fiyat, vs. görsel, yazılı ve işitsel imgelerdir.",
            pTwelve: "İNTERNET SİTESİ KULLANIM KOŞULLARI SÖZLEŞMESİ: SİTE vasıtası ile sunulmakta olan ticari ve kişiye özel nitelikteki hizmetlerden yararlanacak gerçek veya tüzel kişilerle DEF24 arasında elektronik ortamda akdedilen işbu sözleşmedir.",
            pThirteen: "KİŞİSEL BİLGİ: Üyenin kimliği, adresi, elektronik posta adresi, telefon numarası, IP adresi, SİTE'nin hangi bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret tarihi, saati, vs. bilgilendirilir.",
            pFourteenTitle: "HİZMETLERİN KAPSAMI",
            pFourteen: "DEF24'nin, SİTE üzerinden sunacağı hizmetler sınırlı olmamak üzere; DEF24'e ait def24.com adresinde satışa sunulan ürünlerin; ÜYE tarafından bedeli ödendikten sonra, tedarikçinin stok durumunun müsait olması halinde" + '"sözleşmenin satıcıya yüklediği, malın teslim edilmesi"' + "borcunun ifa edilebilir olması durumunda, taahhüt edilen sürede malın müşteriye kargo firması tarafından DEF24 adına ayıpsız olarak teslimidir.",
            pFifteen: "DEF24, SİTE üzerinden vereceği hizmetlerin kapsamını ve niteliğini belirlemekte tamamen serbest olup, hizmetlere ilişkin olarak yapacağı değişiklikleri SİTE'de yayınlamasıyla yürürlüğe koymuş kabul edilir.",
            pSixteen: "SİTE bünyesinde sunulacak hizmetlerden yararlanabilmek için kullanıcıların DEF24 tarafından belirlenecek ve SİTE'nin ilgili bölümünün içeriğinde belirtilecek özellikleri taşıması gerekmektedir. DEF24, bu özellikleri belirlemekte tamamen serbest olup, özelliklere ilişkin olarak yapacağı değişiklikleri SİTE'de yayınlaması ile yürürlüğe koymuş kabul edilir.",
            pSeventeenTitle: "GENEL HÜKÜMLER",
            pSeventeen: "SİTE üzerinden, DEF24'nun kendi kontrolünde olmayan ve başkaca üçüncü kişilerin sahip olduğu ve işlettiği başka internet sitelerine veya başka içeriklere link verebilir. Bu linkler KULLANICI'lara ve ÜYE'lere yönlenme kolaylığı sağlamak amacı ile konmuş olup herhangi bir internet sitesini veya o siteyi işleten kişiyi desteklememektedir. Link verilen internet sitesinin içerdiği bilgilere yönelik herhangi bir türde bir beyan veya garanti niteliği taşımamaktadır. SİTE üzerindeki linkler vasıtası ile erişilen internet siteleri ve içerikleri hakkında DEF24'nun herhangi bir sorumluluğu yoktur. Bu sitelerin kullanımından doğacak zararlar, KULLANICI'ların ve ÜYE'lerin kendi sorumluluğundadır. DEF24, üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt etmemektedir.",
            pEighteen: "SİTE'yi kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla SİTE üzerinden işlem yapabilirler. KULLANICI'ların ve ÜYE'lerin, SİTE dahilinde yaptığı her işlem ve eylemdeki hukuki ve cezai sorumlulukları kendilerine aittir. Her kullanıcı ve her üye, DEF24 veya başka bir üçüncü kişinin haklarına tecavüz teşkil edecek nitelikteki herhangi bir faaliyette bulunmayacağını taahhüt eder. KULLANICI ve ÜYE'lerin SİTE üzerindeki faaliyetleri nedeniyle üçüncü kişilerin uğradıkları ve uğrayabilecekleri zararlardan dolayı DEF24'nun doğrudan veya dolaylı hiçbir sorumluluğu yoktur.",
            pNineteen: "İşbu SİTE'nin sahibi DEF24'dur. Bu sitede bulunan bilgileri yazılar, resimler, markalar, slogan ve diğer işaretler ile sair sınai ve fikri mülkiyet haklarına ilişkin bilgilerin korunmasına yönelik programlar ile, sayfa düzeni ve SİTE'nin sunumu DEF24'nun veya DEF24'nun izin ve lisans aldığı kuruluşların mülkiyetindedir. İşbu SİTE'deki bilgilerin veya SİTE sayfalarına ilişkin her tür veritabanı, internet sitesi, software-code'ların, html kodu ve diğer kodlar vs. ile SİTE içeriğinde bulunan ürünlerin, tasarımların, resimlerin, metinlerin, görsel, işitsel vesair imgelerin, video kliplerin, dosyaların, katalogların ve listelerin kısmen veya tamamen kopyalanması, değiştirilmesi yayınlanması, online veya diğer bir medya kullanılmak suretiyle gönderimi, dağıtımı, satılması yasaktır. KULLANICI ve ÜYE, yukarıda belirtilen ve bunlarla sınırlı olmayan SİTE yazılım, donanım ve içeriğini çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, gerek bu eylemleri ile gerekse de başka yollarla DEF24 ile doğrudan veya dolaylı olarak rekabete girmeyeceğini kabul ve taahhüt etmektedir. KULLANICI ve ÜYE, DEF24 hizmetlerini, DEF24 bilgilerini ve DEF24'nun telif haklarına tabi çalışmaları yeniden satmak, işlemek, paylaşmak, dağıtmak, sergilemek veya başkasının DEF24'nun hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip değildir. Bu sayfadaki bilgilerin kısmen kopyalanması, dağıtılması, çoğaltılması, sergilenmesi ancak ticari olmayan kişisel ihtiyaçlar için ve DEF24'nun yazılı izni ile mümkündür.",
            pTwenty: "DEF24, SİTE üzerinden KULLANICI'lar ve ÜYE'ler tarafından kendisine iletilen bilgileri" + '"Gizlilik Politikası"' + "ve" + '"İnternet Sitesi Kullanım Koşulları"' + "hükümleri doğrultusunda kullanılabilir. Bu bilgileri işleyebilir, bir veritabanı üzerinde tasnif edip muhafaza edebilir. DEF24 aynı zamanda, KULLANICI ve ÜYE'nin kimliği, adresi, elektronik posta adresi, telefon numarası, IP adresi, SİTE'nin hangi bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret tarihi, saati vs. bilgileri de istatistiki değerlendirme, kampanyaların duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi amaçlarla kullanılabilir. KULLANICI'lara ve ÜYE'lere ait kişisel bilgiler, kanunla yetkili kılınan mercilerin talebi ve aşağıda sayılan haller hariç olmak üzere gerçek ve tüzel üçüncü kişilere açıklanmayacaktır. Kişisel bilgiler ender olarak DEF24 için veya onun adına davranan üçüncü taraflara veya DEF24'nun işi ile ilgili olanlara verilerin özgün olarak kullanım amaçlarını daha iyi işlemek ya da KULLANICI'ların ve ÜYE'lerin önerdiği amaçlara uygun olarak daha iyi hizmet verebilmek üzere verilir.",
            pTwentyOne: "İşbu SİTE Kullanım Koşulları dahilinde DEF24 tarafından açıkça yetki verilmesi hallerde DEF24, DEF24 hizmetleri, DEF24 bilgileri, DEF24 telif haklarına tabi çalışmaları, DEF24 ticari markaları, DEF24 ticari görünümü veya bu SİTE vasıtası ile sağladığı başkaca varlık ve bilgilere yönelik tüm haklarını saklı tutmaktadır.",
            pTwentyTwo: "Reşit olmayan veya tüzel kişi üyeler için tüzel kişiyi temsil ve ilzam etmeye yetkili olmayan kişiler kanalıyla yapılan başvurular, kayıt işlemlerini tamamlamış olsa dahi Üye veya Kullanıcı olmanın doğurduğu haklardan yararlanamazlar. Aksi yöndeki talep ve işlemler DEF24'in sorumluluğunda değildir.",
            pTwentyThreeTitle: "SORUMLUĞUN SINIRLANDIRILMASI",
            pTwentyThree: "DEF24, SİTE'ye erişilmesi, SİTE'nin veya SİTE'deki bilgilerin ve diğer verilerin programlarının vs. kullanılması sebebi ile, sözleşmenin ihlali, haksız fiil veya başkaca nedenlere binaen, doğabilecek doğrudan veya dolaylı hiçbir zarardan sorumlu değildir. DEF24, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde, işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul etmez. Bu SİTE'ye ya da link verilen diğer internet sitelerine erişilmesi veya SİTE'nin kullanılması ile DEF24'nun, kullanım/ziyareti sonucunda, doğabilecek her türlü sorumluluktan, mahkeme ve diğer masraflar da olmak üzere her tür zarar ve talep hakkından ayrı kılındığı kabul edilmektedir.",
            pTwentyFourTitle: "FİKRİ MÜLKİYET HAKLARI",
            pTwentyFour: "SİTE'deki genel görünüm, tasarım, metin, imge logo, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan teknik verileri, DEF24 markası, uygulanan iş metodu ve modeli yazılım kodu ve diğer kodlar da dahil ve bunlarla sınırlı olmamak kaydıyla tüm unsurları DEF24'a aittir ve DEF24 tarafından üçüncü bir kişiden alınan telif hakkı altında kullanılmaktadır. Fikri ve Sınai haklar kapsamında korunan DEF24'a ait tüm bu unsurlar çoğaltılamaz, başka bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz, paylaşılamaz, dağıtılamaz, sergilenemez, bu sözleşme dışında kullanılamaz veya bunlardan türetilerek çalışmalar yapılamaz veya hazırlanamaz, aksi şekilde davranılması halinde, sorumlu kişi veya kişiler DEF24'nun uğradığı zararı ve lisans verenler de dahil üçüncü kişilerin uğradıkları zararlardan dolayı DEF24'dan talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık ücreti de dahil olmak üzere karşılamakla yükümlü olacaklardır.",
            pTwentyFiveTitle: "SÖZLEŞMENİN DEVRİ",
            pTwentyFive: "DEF24, bu sözleşmeyi bildirimsiz olarak istediği zaman kısmen veya bütünüyle devredebilir. Ancak KULLANICI ve ÜYE bu sözleşmeyi veya herhangi bir kısmını başka bir tarafa devredemez. Bu türden bir devir girişimi geçersizdir.",
            pTwentySixTitle: "MÜCBİR SEBEPLER",
            pTwentySix: "Hukuken mücbir sebep sayılan tüm durumlarda, DEF24 işbu" + '"İnternet sitesi kullanım koşulları ve üyelik sözleşmesi"' + "nı geç ifa etmekten veya ifa etmemekten dolayı yükümlü değildir. Bu ve bunun gibi durumlar, DEF24 açısından, gecikme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için DEF24'nun herhangi bir tazminat yükümlülüğü doğmayacaktır.",
            pTwentySevenTitle: "UYGULANACAK HUKUK VE YETKİ",
            pTwentySeven: "İşbu" + '"İnternet sitesi kullanım koşulları ve üyelik sözleşmesi"' + "ndan kaynaklanacak ihtilaflar Türk Hukuku'na tabidir ve yetkili mahkeme Florida, USA Mahkemeleri ve İcra Daireleri'dir.DEF24'nun, KULLANICI ve ÜYE'nin bulunduğu ülkede dava açma hakkı saklıdır.",
            pTwentyEightTitle: "YÜRÜRLÜK VE KABUL",
            pTwentyEight: "İşbu" + '"İnternet sitesi kullanım koşulları ve üyelik sözleşmesi"' + ", DEF24 tarafından SİTE içeriğinde ilan edildiği tarihte yürürlük kazanır. KULLANICI' lar ve ÜYE' ler, işbu sözleşme hükümlerini SİTE'yi kullanmakla kabul etmiş olmaktadırlar. DEF24, dilediği zaman işbu sözleşme hükümlerinde değişikliğe gidebilir, değişiklikler, sürüm numarası ve değişiklik tarihi belirtilerek SİTE üzerinden yayınlanır ve aynı tarihte yürürlüğe girer."
        },

    },
    errorPage: {
        title: "404 Hata",
        keywords: "404, hata",
        link: "/404",
        description: "Hata Sayfası",
        definition: "Aradığınız Sayfa Bulunamadı."
    }

}
