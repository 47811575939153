import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script'

const Meta = ({
    title,
    keywords,
    description,
    alternateLanguage,
    alternateLanguageEn,
    lng
}) => {
    const router = useRouter();
    let lang = router.locale === "tr";
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
                <meta name="author" content="DEF24.com" />
                <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=5.0, shrink-to-fit=no" />
                <link rel="icon" href="/favicon.png" type="image/x-icon" />
                <link rel="apple-touch-icon" href="/apple-icon.png" />
                <link rel="alternate" hrefLang="x-default" href={alternateLanguageEn} />
                <link rel="alternate" hrefLang="en" href={alternateLanguageEn} />
                <link rel="alternate" hrefLang="tr" href={alternateLanguage} />
                <link rel="canonical" href={lang ? alternateLanguage : alternateLanguageEn} />
                {/* <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css"
                /> */}
                <title> {title} </title>
            </Head>
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-Y57Q2392MN" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-Y57Q2392MN');
        `}
      </Script>
            {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y57Q2392MN" defer /> */}
            {/* <script
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-Y57Q2392MN');`
                }}
                defer async
            /> */}
            <script type="text/javascript" src={lang ? "//autocookie.org/basic.php?pp=/tr/gizlilik&debug&pos=bottom&target=_self&lmore=Detay gör&dismiss=Onayla&msg=Bu site verimliligi sağlamak icin çerez kullanmaktadır" :
                "//autocookie.org/basic.php?pp=/privacy&debug&pos=bottom&target=_self"}
                async />
        </>
    )
}


export default Meta;