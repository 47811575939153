/* eslint-disable import/no-anonymous-default-export */
export default {
    home: {
        title: "Information Tech. and Cyber Security",
        link: "/",
        keywords: "cyber security, tscm, pentest, penetration test, audit, data security, cyber consultancy, ip tools",
        description: "Main services Cyber Security, Penetration Tests, TSCM, Data Security, Cyber Consultancy and Cyber Training. ",
        companyName: "DEF24 IT AND DEFENCE COMPANY",
        slogan: "Your Defense Power in Information Security",
        videoButton: false,
        orangeTitle: "32 Years of Experience",
        // CONSULTANCY
        dataConsult: {
            title: "Consultancy",
            isoTitle: "Cyber Security",
            p: "Providing consultancy on network systems, software cyber security architecture, cloud security, physical environment and company security policies.",
            isoLink: "/services/consultancy/cyber",
            // gdprTitle: "GDPR",
            // p1:"Offering 100% dominance and 100% solution to the issue with our expert teams on the two essential elements of GDPR.",
            // gdprLink: "/services/consultancy/gdpr",
            domainTitle: "Domain",
            domainLink: "/services/consultancy/domain",
            p2: "Preventing, predicting and suggesting solutions for possible interruptions about domain names in the later stages of your projects."
        },
        toolsData: {
            title: "Tools",
            passwordTitle: "Location Tools",
            passwordItem: [
                { name: "IP8", link: "https://ip8.com" },
                { name: "IP Location", link: "/tools/geoip" },
                { name: "Reverse Hosting", link: "/tools/reverse-hosting" }
            ],
            passwordDefinition: "IP location, detail and Reverse DNS record query service.",
            ipTitle: "Security Tools",
            ipTools: [
                { name: "Mail1", link: "https://mail1.io" },
                { name: "Password Generator", link: "/tools/password-generator" },
                { name: "Passphrase Generator", link: "/tools/passphrase-generator" }
                ],
            ipDefinition: "Secure email and password generation service.",
            domainDetailTitle: "Domain Details",
            otherToolsTitle: "Research Tools",
            otherToolsItem: [
                { name: "CyberChef", link: "https://cyberchef.org" },
                { name: "DNSLeakTest", link: "https://dnsleaktest.org" },
            ],
            otherDefinition: "Analysis of data, decoding and detection of DNS leaks."
        },
        itDatas: {
            upTitle: "IT Security",
            upParagraf: "Your data is protected with DEF24 to detect technology theft, disk-, network-, and efficiency analysis.",
            upLink: '/services/it/data-security',
            buttonName: 'More Info',
            belowLink: '/services/it/reverse-engineering',
            belowTitle: "Reverse Engineering",
            belowPargraf: "DEF24 provides reports by analyzing the open-source and compiled materials with both active and passive methods.",
            upperAlt: "data security",
            belowAlt: "reverse engineering"
        },
        pentestDatas: {
            title: "Top Services",
            subTitle: "Penetration Tests",
            definitionOne: "All possible attack scenarios that may target you are simulated with our comprehensive tests.",
            definitionTwo: "Suggestions regarding vulnerabilities of your systems are reported to find optimal solutions.",
            buttonName: "More Info",
            link: "/services/cyber/penetration-test",

        },
        tscmDatas: {
            title: "Technical Surveillance Countermeasures",
            definitions: "DEF24 provides a safe environment for its customers by performing radio-frequency analyzes, infrared examinations, and Spyware Analysis with IT experience and technological devices.",
            link: "/services/tscm",
            buttonName: "More Info",
        },
        whyDatas: {
            secondTitle: "Reasons to Prefer DEF24",
            items: [
                {
                    src: '/images/whychoose/knowledge.png',
                    alt: "experience and knowledge",
                    lowerTitle: '32 Years of Experience and Knowledge',
                    definition: 'We have started serving in the IT and Information Security in Turkey and the US, independent of our company operating in Germany.',
                    delay: '600'
                },
                {
                    src: '/images/whychoose/global.png',
                    alt: "wide service",
                    lowerTitle: 'Wide Service Network',
                    definition: 'Serving in 5 main categories globally; namely Cyber Security, Information Security, Technical Surveillance Countermeasures, Consultancy and Training.',
                    delay: '800'
                },
                {
                    src: '/images/whychoose/protect-sword.png',
                    alt: "detection devices",
                    lowerTitle: 'Detection and Blocking',
                    definition: 'Detection and blocking activities for Cyber Security, Information Security, and Environment Security are carried out with advanced and state-of-the-art devices.',
                    delay: '1000'
                },
                {
                    src: '/images/whychoose/osint.png',
                    alt: "ip tools",
                    lowerTitle: 'Advanced IP Queries',
                    definition: 'Responding all the questions you may have about a particular IP with our advanced infrastructure.',
                    delay: '300'
                },
                {
                    src: '/images/whychoose/professional.png',
                    alt: "expert",
                    upperTitle: 'Personel',
                    lowerTitle: 'Professional and Expert Team',
                    definition: 'Our experienced team offers professional solutions to the needs of all our customers to whom we will serve.',
                    delay: '500'
                }
            ],
        },

    },
    about: {
        title: "Company",
        link: "/company",
        keywords: "coorparate, human resources, about company",
        description: "Providing world-class services in the Cyber Security, Information Security, Technical Surveilance Counter Measures, Training, and Consultacy.",
        rotatorFirst: "Cyber Security",
        definitionOne: "DEF24 Inc. was established by combining 32 years of experience in network, software, informatics and hardware in the USA, independent of our company operating in Germany, to provide world-class services in the",
        definitionTwo: "Cyber Security, Information Security, Technical Surveillance Counter Measures, Training and Consultancy.",
        visionTitle: "Vision",
        visionPar: "To take our brand's place in the list of the best in Turkey and the World to the top with maximum capability by keeping up with the latest technologies developed in informatics.",
        missionTitle: "Mission",
        missionPar: "To identify and explain the possible problems in the IT sector, the causes, and solutions of these problems to the users with Training, Awareness, and Consultancy activities, and to offer up-to-date and best solutions.",
        whyUsTitle: "Why Us",
        whyUsPar: "DEF24 carries out the detection and blocking activities for Cyber Security, Information Security, Technical Surveillance Countermeasures with the most advanced and technological devices.",
        humanResources: {
            title: "Human Resources",
            definitionOne: "DEF24 Inc. supports its employees in improving themselves in their fields, increasing their skills, and continuing their social lives with high quality. So it provides opportunities for sectoral training, certificates, and exams. If you want to be a bright member of the DEF24 team, send your CV to ",
            mail: 'ik@def24.com',
            definitionThree: "In DEF24, women's employment is prioritized.",
        },
        photoParag: "Provides world-class services in the Cyber and Information Security with the most advanced technological devices and qualified personel."
    },

    contact: {
        title: "Contact",
        link: "/contact",
        keywords: "contact information data",
        description: "Company contact, email, phone, adress and location detail data.",
        questionTitle: "Do you have any questions or suggestions?",
        company: "USA, Miami. Company HQ",
        companyOffice: "Turkey, Antalya. Office.",
        placeHolderOne: "Name",
        placeHolderTwo: "Email",
        placeHolderThree: "Subject",
        placeHolderFour: "Message",
        buttonName: "Send Message",
        contactNumber: "+1-305-5150578",
        contactNumberTr: "+1-305-5150578",
        contactAdress: "Def24 Inc. 815 Ponce De Leon Blvd Coral Gables FL 33134 USA",
        coordinatesLat: 25.7638449,
        coordinatesLong: -80.2591029,
        contactAdressTr: "Çaybaşı Mah. 1358 Sok. Premier Plaza 07100 Antalya Turkey",
        coordinatesLatTr: 36.8876831,
        coordinatesLongTr: 30.7219744,
        success: "Success",
        error: "Error",
        successMessage: "We take your message, will reply as soon as possible",
        errorMessage: "An error occured, resend your message",
    },
    pentest: {
        title: "Penetration Tests",
        link: "/services/cyber/penetration-test",
        keywords: "cyber security, penetration tests, pentest",
        description: 'The information systems are evaluated with penetration tests by 32 years of experienced team leaders with a special methodology.',
        pentestDatas: {
            upperTitle: "Cyber Security",
            documentTitle: "Penetration Tests",
            pTitleOne: 'Penetration Test (PENTEST)',
            pOne: 'A penetration test is a simulated attack carried out by experts to evaluate the security of the information infrastructure of the systems.',
            pTwo: 'The possibility of an unauthorized person accessing system features and data, causing damage is evaluated under the leadership of our 32 years of experienced team leaders with a particular methodology for that system with physical penetration tests, social engineering penetration tests, wifi penetration tests, network penetration tests, software and application penetration tests, mobile device penetration tests, server penetration tests, network device penetration tests of the target systems authorized by our customers.',
            pTwoA: 'The rate of intended use should be increased, and penetration tests should be carried out at regular intervals due to the mobility of elements such as new authorized identification, authorization termination, new hardware, new software to achieve maximum protection of the systems.',
            pTwoB: "In addition, the GDPR data storage processes of our customers' systems are reported in detail in line with the evaluation of GDPR experts of our company.",
            pTwoC: 'Penetration tests can be carried out with three methods;',
            pThreeTitle: 'Black Box Method:',
            pThree: 'In the tests to be carried out with this method, the negativities created by a hacker are analyzed with the information obtained in accordance with the company contract in the attack simulations.',
            pFourTitle: 'Gray Box Method:',
            pFour: 'In the tests to be carried out with this method, detection of uncontrolled activities of persons with specific authorizations and access rights in the internal structure of the system and the access of persons without any permission to access are analyzed with limited information exchange in addition to the information obtained by the company contract.',
            pFiveTitle: 'White Box Method:',
            pFive: 'In the test to be carried out with this method, how persons with control over the system can access unauthorized areas and how they can damage is analyzed by sharing the system- and background information and the information obtained by the company contract. This test requires the most extensive coordination and time.',
            pSix: 'There are two types of target access locations where the Pentest will be carried out.',
            pSevenTitle: 'Internal Penetration Test,',
            pSeven: ' is the test to be carried out over the local networks and systems of the relevant target.',
            pEightTitle: 'External Penetration Test, ',
            pEight: "is the test to be carried out on the relevant target's website, servers, and applications.",
            titleMet: "PENETRATION TEST METHODS",
            pNineTitle: 'Physical Penetration Test',
            pNine: 'This is the test that our team will physically test unauthorized access to system targets within the planned time interval with authorized administrators. If physical environments are unprotected against physical penetration, these are exposed to many digital attacks. These are the tests carried out within specific planning with the tools that are standard around the world.',
            pTenTitle: 'Social Engineering Penetration Test',
            pTen: 'These are the tests to report the possibility of gaining information and activity from authorized persons from human-based factors with the computer, human, phishing methods within the planned time interval with our team of authorized counterparts.',
            pElevenTitle: 'Wireless Network Penetration Test',
            pEleven: 'Wi-Fi systems are systems that are highly preferred for speed and ease of installation, but these systems provide communication with radio frequencies that everyone in the air can notice. Despite new Wi-Fi standards being issued every two years to prevent security vulnerabilities, users should keep the security of the systems under their control in this process. DEF24 provides security reporting services by testing the actions of those trying to penetrate the internal network and taking control over the licensed equipment.',
            pTwelveTitle: 'Network Penetration Test',
            pTwelve: "The importance of informatic channels is pretty straightforward when it is thought that every possible type of data is transmitted with informatic channels, and these consist of networks. While unauthorized access with certain internal information is tested internally with the network penetration test carried out with the tools and programs specially designed for our customers' systems, other tests are carried out externally as hackers; thus, it leads to the most comprehensive test. DEF24 recommends that our customers request the network penetration test at regular intervals.",
            pThirteenTitle: 'Software and Application Penetration Test',
            pThirteen: "Websites and applications (apps) designed by our customers within the scope of software activities are systems that can be penetrated from the outside, thus accessing many elements such as customer data, financial data, personal data and causing damage to corporate image. It cannot be expected from system designers and programmers to know security vulnerabilities like cybersecurity experts. DEF24 carries out your systems' software and application penetration tests with special methods with 32 years of experience and professional staff.",
            pFourteenTitle: 'Mobile Device Penetration Test',
            pFourteen: 'Devices with a particular operating system, such as smartphones, tablets, smartwatches, smart TVs, e-readers existing in almost every moment of our lives, can create significant security weaknesses. The vulnerabilities in the systems cannot be ignored despite the use policies being the most critical security measure. System vulnerabilities and use without complying with the rules cause significant security vulnerabilities in internal systems. Mobile device penetration tests are carried out as a branch parameter for the maximum security of our customers. ',
            pFifteenTitle: 'Server Penetration Test',
            pFifteen: 'Nowadays, servers or similar systems that provide standard services forming the infrastructure of the operation and efficiency of the systems exist in many companies. These servers generally operate 24/7 and serve the desired number of people. The importance of security vulnerabilities that may occur in servers is of high importance. DEF24 carries out the tests internally and externally due to the cards that are open to outside.',
            pSixteenTitle: 'Network Device Penetration Test',
            pSixteen: 'It is a test service carried out in cooperation with the system administrators of the devices on the network, such as printers, routers, firewalls, switches, from an internal location.'

        }
    },
    search: {
        title: "Search Results",
        link: "/search",
        keywords: "search",
        description: "Search Results of webpage datas",
        upperTitle: "Search Results",
        noResultMess: "No result data was found"
    },
    reverse: {
        upperTitle: "Tools",
        title: "Reverse Hosting",
        link: "/tools/reverse-hosting",
        keywords: "reverse hosting, reverse ip query",
        description: 'Reverse hosting and reverse ip query tool is used to find out all the other websites that are hosted at the same server.',
        info: 'You can use this tool to reverse lookup a given hostname and its ip and find out all the other websites they are hosted at the same server. This is an exclusive service which is only offered here. You may use this tool to gather more information on your competition or partners.',
        lookupTitle: "Reverse Hosting Lookup Results",
        resultCountMessage: "Total",
        maximumLengthMessage: "There are & results but this view is limited to 100 for speed reasons",
        placeHolder: "Ip/Domain",
        buttonSearch: "Lookup",
        buttonClear: "Clear",
        infoMessage: "You see all the sites we have found hosted at the ip-address &, some of them might have changed their ip but they were hosted under this ip within last 4 weeks. This list has been sorted by accuracy. The top hostnames are most likely the ones they do have more traffic. You might want to click them to open these sites.",
        messMissing: "Missing Parameter",
        messNoFound: "We have searched our records from last 4 weeks about your query & and could not find any match. This address might host no website or we have not crawled any website which is hosted at this ip address. You might want to change your query and search for something else."
    },
    password: {
        upperTitle: "Tools",
        title: "Password Generator",
        keywords: "secure password generator",
        keywordsPassphrase: "secure passphrase generator",
        link: "/tools/password-generator",
        passphraseLink: "/tools/passphrase-generator",
        description: "100% secure and safe password generator feature. Exclusive free and trusted.",
        descriptionPassphrase: "100% secure and safe passphrase generator feature. Exclusive free and trusted.",
        tabOne: "Password",
        tabTwo: "PassPhrase",
        phraseTitle: "Passphrase Generator",
        params: {
            notePassword: "Good passwords are at least 12 characters long. If you can, include letters, " +
                "numbers, & symbols in random order. Make it as long as your account provider" +
                " will allow, you won’t be typing it anyway once you get a password manager.",
            notePassphrase: "Long key passwords are easy to remember (and write!) And as secure as a random string," +
                ' especially when they are longer than a password. It is the perfect choice for your "master" password.',
            sliderLabelPassword: "Length",
            sliderLabelPassphrase: "Word Count",
            checkBoxLabelPassword: "Character Selection",
            checkBoxLabelPassphrase: "Divider",
            checkBoxSelectionPhraseOne: "Hyphen",
            checkBoxSelectionPhraseTwo: "Space",
            checkBoxSelectionPhraseThree: "Period",
        },
        instructionsData: {
            title: "How does this work?",
            items: [
                "Set your parameter", "Choose a secret, any will work", "Click to copy", "Enjoy your day"
            ]
        },
        notes: ["Your secrets are generated on this device with a ", "cryptographically strong random number generator",
            " and are not transmitted or persisted in any way. Just look out for nosy parkers and close this browser tab when you're done."
        ],
        about: {
            title: "Good Password Hygiene",
            subTitle: "Don’t be a statistic ! Secure passwords are your first defense when safeguarding against threats online.",
            listItems: [
                'Don’t use personal info in your passwords, like your dog’s birthday. Duhh.',
                'Don’t share credentials over email or text message. They’re completely wide-open to interception by 3rd parties.',
                'Use a different password for every account. If a baddie hacks that meme generator site you used once 8 months ago and you re-use the same password on all your accounts, you may be up the creek. And now you have to change the password on all 308 of your other accounts. (That sucks.) Yes, a unique password for every account is pretty much impossible with out a password manager...',
                'Use a password manager. You have plenty of options , many of which include a free tier. Get one with a mobile app that syncs between all your devices, that way you’ll always have your passwords on you. Usea long but easy to remember "master" passphrase like craig-accuracy-czech-shall-venice to secure your password vault, and long random passwords for individual accounts. Thankfully, browsers and operating systems now almost all have basic password management built it. And with the rise of biometric access controls (Face ID, et al) there’s really no excuse not to use long complex passwords everywhere.',
                'Answer security questions with random passwords or passphrases and store them in your password manager too. Some of this information, like your mother’s maiden name, is bound to be already be floating around out there. Most managers now include this functionality.',
                'Change your “master” password periodically. It only takes a few minutes to permanently memorize a 5-word passphrase.'
            ]
        }
    },
    geoip: {
        upperTitle: "Tools",
        title: "IP LOCATION",
        keywords: "ip, location of ip",
        link: "/tools/geoip",
        description: "location of IP, IP lookup, query and your system details",
        info: "This information is just an approximation and likely inaccurate. It's based on the IP address and information available on public servers.",
        lookupTitle: "IP Address Lookup/Details",
        placeHolder: "IP or Hostname",
        placeHolderDetail: "You can paste logs or multiple ips into this area, also hostname is supported",
        buttonSearch: "Lookup",
        buttonClear: "Clear",
        locationName: "Location",
        ipdetailstitle: "IP Details",
        systemTitle: "Your System Info",
        opTitle: "Operating System",
        browser: "Browser",
        browserLang: "Browser Language",
        userA: "User Agent",
        accept: "HTTP Accept",
        encoding: "HTTP Accept-Encoding",
        lang: "HTTP Accept-Language",
        conn: "HTTP Connection",
        close: "Close",
        providerTitle: "Providers"

    },
    gdpr: {
        title: "GDPR",
        keywords: "gdpr, personal data protection",
        link: "/services/consultancy/gdpr",
        description: "General Data Protection Regulation (GDPR) and personal data protection consultancy.",
        upperTitle: "Consultancy",
        paragrafOne: "General Data Protection Regulation (GDPR) aims to protect individuals' fundamental rights and freedoms, especially privacy, and regulate obligations and procedures. Principles to be followed by civil and legal persons who process personal data. DEF24 offers 100% dominance and 100% solution to the issue with its expert teams on the two essential elements of GDPR, namely INFORMATION SECURITY and PROCESS EXPERTISE Law on Protection of Personal Data (GDPR).",
        specialData: "Within the scope of GDPR consultancy;",
        paragrafTwo: "DEF24 provides a comprehensive legal consultancy service regarding the protection of personal data, including defining obligations, reviewing and adapting data recording systems, contracts, and related forms by these obligations, reviewing the policies, procedures, and regulations regarding the collection, processing, sharing, and deletion of personal data and making necessary changes and/or recreation of these where needed.",
        paragrafThree: "The GDPR consultancy services provided by DEF24 also includes consultancy on the implementation of necessary changes without disrupting the workflows by examining the business processes of the institutions in detail within the scope of personal data protection and compliance with the law and identifying the missing or correction points needed for the compliance of the processes with the relevant personal data protection legislation.",
        paragrafFour: "DEF24 provides consultancy services on data classification, labeling, storage, archiving, backup, destruction, business continuity, security management for your technical infrastructure, endpoint, e-mail, and messaging security solutions.",
        paragrafFive: "Persons and institutions processing personal data can carry out their business processes in a healthy way and ensure business continuity safely by securing the confidentiality and integrity of the data they own and by controlling their access rights. DEF24 also provides cybersecurity consultancy services to ensure and control the connections with the outside world, which is one of the main factors that threaten data security (both at the system and user level)."

    },
    iso27001: {
        title: "ISO-27001",
        keywords: "iso 27001, information security management certification, iso 27001 consultancy",
        link: "/services/consultancy/iso27001",
        description: "ISO 27001 Information Security management certification and consultancy service.",
        upperTitle: "Consultancy",
        documentTitle: "ISO 27001 Information Security Management System (ISMS)",
        paragrafOne: "ISO 27001 Certificate is a certificate program that all our customers, regardless of their company's size, require or are obliged to certify the information security management systems established for confidentiality, integrity, and accessibility obtain upon confirmation of these systems by independent certification bodies.",
        paragrafTwo: "Information security can be ensured due to checking each of our customers' security policies, practices, operating methods, organizational structures, and software elements, respectively, and in relation to each other. DEF24 customers with the relevant certification requirements increase their potential to manage and protect their valuable information assets.",
        paragrafThree: "Our consultancy services, especially during the installation phase, lead our customers to obtain many valuable requirements such as necessary documents, procedures, contracts, certificates, qualifications and to accelerate the process with the proper steps."
    },
    domainConsult: {
        title: "Domain Consultancy",
        keywords: "domain consultancy, wipo, domain security, domain appraisal",
        link: "/services/consultancy/domain",
        description: "Preventing, predicting and suggesting solutions for possible interruptions about domain names in the later stages of your projects.",
        domainDatas: {
            upperTitle: "Consultancy",
            documentTitle: "Domain Consultancy",
            paragrafTitleOne: "With Domain Consulting, ",
            paragrafOne: "DEF24 provides reporting services to prevent, predict and suggest solutions for possible interruptions that may arise due to domain names in the later stages of their projects by examining and reporting all necessary details that our customers may encounter for the domain names they own or will own. DEF24 provides consultancy services to our customers with 32 years of experience and professional staff in the WIPO process, domain intelligence, domain valuation, and security consultancy.",
            paragrafTitleTwo: "Consultancy for WIPO Process,",
            paragrafTwo: " is the consultancy service to protect and defend the rights of our customers when they have different people own domain names of their brands (trademark) or similar domain names (typo) or extensions. During the process, DEF24 helps legal advisors of its clients with the collection of technical information, the preparation of the technical report, and the analysis of the intelligence information. However, the whole process can be followed up by DEF24 if requested.",
            paragrafTitleThree: "Domain Name Intelligence Consultancy (Domain Intelligence), ",
            paragrafThree: "DEF24 provides reports for the domain names owned or owned by our customers, who and for what purpose the domain names are used earlier on, and whether these domain names will create problems for you in the future. As a result of GDPR laws in 2018, it has become difficult for you to have the previous detailed information of the domain names you want to buy, and we also help our customers in this regard.",
            paragrafTitleFour: "Domain Appraisal, ",
            paragrafFour: "is the service of determining the value of the domain names owned or owned by our customers worldwide and reporting. Ten main factors benefitted in value determination lead you to sell your domain both quickly and at its value.",
            paragrafTitleFive: "Domain Security Consultancy, ",
            paragrafFive: "DEF24 provides consultancy services to our customers by preparing detailed reports on issues such as acquiring domain names they have or will have, possible problems they may experience, theft of your data over the domain name, and the security of the domain name."
        }
    },
    cyberConsult: {
        title: "Cyber Security Consultancy",
        keywords: "cyber security, network systems security, software cyber security, cloud security",
        link: "/services/consultancy/cyber",
        description: "Providing consultancy on network systems, software cyber security architecture, cloud security, physical environment and company security policies.",
        cyberConsultDatas: {
            upperTitle: "Consultancy",
            documentTitle: "Cyber Security Consultancy",
            contentTitle: 'Cyber Security Consultancy',
            pOne: "DEF24 provides consultancy services with its expert teams for our customers to have integrated cybersecurity measures for information systems, especially at the beginning of new projects.",
            pTwo: "DEF24 provides consultancy on the creation, development, and control of the network systems, software cyber security architecture, cloud security, physical environment, and company security policies.",
            pTitleThree: "Security Consultancy of Network Systems",
            pThree: "Security vulnerabilities that may occur externally and internally should be designed by cybersecurity experts while network systems should be designed structurally by the administrators during the installation process, and maximum protection should be provided. It is possible to re-design existing network systems in cybersecurity, but it is a costly and time-consuming process compared to new installation planning. DEF24 is ready for the security of your systems in line with your system administrators with our 32 years of experience and professional staff.",
            pTitleFour: "Software Architecture Security Consulting",
            pFour: "As a teammate that will control the systems under preparation in the architectural software processes of our customers, DEF24 constitutes the entire security framework of the project under development. It provides reporting service on the cause-effect dilemma of the steps to be taken. Most of the vulnerabilities remain undetected for many years in an environment where everything is digital, and they cause our customers to lose their image and assets. DEF24 provides consultancy services to your software developers to eliminate irreversible problems while your system designers work for you with the highest performance.",
            pTitleFive: "Cloud Security Consultancy",
            pFive: "Most of our systems are hosted in cloud systems due to their elastic structure, technological infrastructure, efficiency, speed, and cost, even if it is not desired. Cloud systems have become necessary and mandatory for many reasons.",
            pSix: "While cloud systems are preferred because of their great advantages, the most important point to be considered is the security of the systems, due to the hundreds of configuration and service combination factors, as cloud providers use a general framework so that the systems can be suitable for every sector.",
            pSeven: "The choice to move to cloud systems is a big decision. The user moves in the cloud system and gets used to it as a result of the special infrastructure for them prepared by the system providers. While it is easy to move into this system, it is only possible to get out of this system with the same infrastructure. DEF24 offers you the right steps and maximum protection with our 32 years of experience and professional staff.",
            pTitleEight: "Security Policy Consultancy",
            pEight: "As a result of 32 years of sectoral experience, DEF24 knows that the most important security weakness is the human factor which can be prevented with training and technology use policies. Your employees will innocently apply what they think is the most accurate if you don't offer them a framework on sensitive issues.",
            pNine: "DEF24 provides company policy creation and control services for all uses of information technologies, from signing/terminating of employment contracts to password policies with its experienced staff.",
            pTwelve: "Walk together with DEF24 on the right path."
        }
    },
    audit: {
        title: "Audit",
        keywords: "vulnerability scanning, hardware structure inspection, software structure inspection, network structure inspection",
        link: "/services/cyber/audit",
        description: "Searching and scanning activities conducted specific to our customers' systems fully comprehensive.",
        datasAudit: {
            upperTitle: 'Cyber Security',
            documentTitle: "Vulnerability Scanning and Management (AUDIT)",
            pOne: "Vulnerability scanning and management is the process of analyzing the elements such as hardware, software, version, network structure, physical factors of the systems from an outsider's perspective, and reporting the cause-effect relationships.",
            pTwo: 'Thanks to 32 years of industry experience of DEF24, it is known that as the elements of cybersecurity are very extensive and even though system administrators are aware of many vulnerabilities, the security industry is a game of cat and mouse and thus the vulnerability scanning must be conducted from the highest point and the widest angle.',
            pThree: "The report that DEF24 will present as a result of fault search and scanning activities conducted specific to our customers' systems and being fully comprehensive includes errors, sources of errors, unjust events, and works needed to eliminate these errors.",
            pFour: 'It is recommended to analyze the levels of the countermeasures for a safer system by conducting a penetration test with the method agreed upon when the administrators approve the elimination of vulnerabilities as indicated in the reports.'

        }
    },
    osint: {
        title: "Open Source Intellegence (OSINT)",
        keywords: "osint, open source intelligence, hacking, doxing, social engineering, competitor analysis",
        link: "/services/cyber/osint",
        description: "Protecting our customers against the problems that may occur as a result of the use of open source information.",
        datasOsint: {
            upperTitle: 'Cyber Security',
            documentTitle: 'Open Source Intelligence (OSINT)',
            pOne: 'Open-source intelligence is the reporting cause-effect relationships by analyzing the possibility of unauthorized persons (competitors/malicious people) obtaining information that has not been secured and is accessible to everyone.',
            pTwo: 'DEF24, with 32 years of experience and knowledge, protects its customers against the problems that may occur as a result of the use of (OPEN SOURCE) information that can be accessed without any criminal element in internet systems in activities such as hacking, doxing, social engineering, competitor, location, and advertising analysis.',
            pFive: 'It is not enough to gather open-source intelligence through familiar networks such as Facebook, Twitter, Linkedin, Google. Thousands of companies around the world track and record information through different channels. DEF24 reports the whole internet profile of its customers and enhances the protection rate of your personal and legal identities by preventing the information and documents you chose to be prevented.'
        }
    },
    dataSecurity: {
        title: "Data Security",
        keywords: "data security, it security, data theft",
        link: "/services/it/data-security",
        description: "Our expert team ensures the security of private and confidential data in customers' systems.",
        datasSecurity: {
            upperTitle: 'IT Security',
            documentTitle: 'Data Security',
            pOne: 'Data security is the service of detecting and preventing the private and confidential data of our customers from being available outside of the company and from being accessed by unauthorized persons.',
            pTwo: 'The more critical data is, the more responsibility it brings. All around the world, the importance of storage and processing of personal data by institutions and organizations according to the GDPR is clearly stated, and heavy sanctions are applied for violations. It will take a few minutes to copy the projects that your company spent months preparing. Your data will remain safe with the services to be provided by DEF24 to avoid any unwanted situations.',
            pThree: "The expert team of DEF24 ensures the security of private and confidential data in customers' systems with appropriate methods in line with the demands of our customers. The services provided by DEF24 prevents theft of a project prepared for a particular work which often happens in the sector, rivals from having project proposals and details, unauthorized people from having access to the personnel data processed under GDPR, customer data, financial data, personal files, projects, tenders, company details, etc.",
            pFour: 'The information traffic remains strictly within your company with the software and hardware DEF24 provides, especially for your company. The system established by DEF24 provides the detection and prevention of vulnerabilities in your company, detailed information of vulnerabilities, and records of these.',
        }
    },
    efficienyAnalysis: {
        title: "Efficieny Analysis",
        keywords: "efficiency analysis",
        link: "/services/it/efficiency-analysis",
        description: "Efficiency analysis are handled by us provide your company more cost-effective",
        efficienyAnalysisDatas: {
            upperTitle: "IT Security",
            documentTitle: "Efficiency Analysis",
            pOne: 'Efficiency analysis is a reporting service for system users and company employees to spend their time efficiently for their work.',
            pTwo: "The information traffic remains strictly within your company with the software and hardware DEF24 provides, especially for your company. The system established by DEF24 with the experienced and professional sectoral approach reports efficiency analyses of our customers' system users and employees.",

        }
    },
    reverseEngineering: {
        title: "Reverse Engineering",
        keywords: "reverse engineering, active methods, passive methods, security confirmation, software examination, spy materials on clients' computers",
        link: "/services/it/reverse-engineering",
        description: "DEF24 provides reports by analyzing the open-source and compiled materials with both active and passive methods.",
        reverseEngineeringDatas: {
            upperTitle: "IT Security",
            documentTitle: "Reverse Engineering",
            pOne: 'Reverse engineering is the process of discovering the structure, function, or operation of a system with a reasoning analysis and reaching the original parameters of the design with analysis of the finest details of the existing system.',
            pTwo: "With the experienced and professional sectoral approach DEF24, provides detection and solution reports by analyzing the open-source and compiled materials with both active and passive methods such as malware, ransomware, data theft software, security confirmation of the existing software, examination of the origin and danger level of spy materials on clients' computers.",

        }
    },
    customerTrainee: {
        title: "IT and Cyber Training",
        keywords: "cyber security training",
        link: "/services/training",
        description: "IT environments training are handled differently and comprehensively for users and administrators",
        datasTrainee: {
            upperTitle: 'Training',
            documentTitle: 'Cyber Security Training',
            pOne: "32 years of sectoral experience and scientific research indicates that 92% of attacks in the IT sector are successful due to the human factor.",
            pTwo: "The incorrect, unprotected, unconscious use of technology will bring unwanted results regardless of how advanced the technology systems are. DEF24 appreciates that the human factor in IT environments should be handled differently for system users and system administrators by imposing different responsibilities and obligations on each group. DEF24, as a result of the great responsibility it carries, attaches significant importance to training activities in order to increase the awareness of individuals so they become conscious users.",
            pTitleThree: 'System Users Training',
            pThree: "DEF24 observes that end-users are vulnerable to hackers even when using antivirus and/or firewalls as they do not have sufficient knowledge of social engineering, password creation, simple GDPR rules and password hosting to keep themselves and the system safe by paying attention to the small details. DEF24 provides adequate training and awareness studies that explain how to keep systems safe, and how users can protect themselves while safeguarding company assets.",
            pFour: "DEF24 offers awareness training on *My Safe Internet, Safe Informatics, Safe Data* at all primary, secondary and high school levels. To request awareness training….. DEF24 shows users how to protect themselves, the rights and process at the institutions processing data within GDPR, and studies on social engineering with the awareness training titled *My Safe Data, Safe Informatics* at the request of institutions, organizations and communities.",
            pTitleFive: 'System Administrators and Supervisors Training',
            pFive: "The most important point in our training for system administrators and supervisors is reminding managers that they cannot have extensive knowledge and experience in law, informatics, networking and software, and that system administrators cannot have extensive knowledge and experience in law, software and cybersecurity.",
            pSix: "Thus, DEF24 provides services to maximum system defense by increasing the information of obliged parties with our training on issues such as GDPR, company policies necessary for information security, determination of missed points, the resolution of errors, and reminding those involved that these objectives can be achieved with a big team.",


        }
    },
    tscm: {
        title: "TSCM",
        keywords: "tscm, technical surveillance countermeasures",
        link: "/services/tscm",
        description: "Our capabilites are detection and prevention of the unauthorized listening and monitoring your areas as Technical Surveillance Counter Measures",
        datasTscm: {
            upperTitle: 'TSCM',
            documentTitle: "Technical Surveillance Countermeasures (TSCM)",
            pOne: "DEF24 provides the service of detection and prevention of the unauthorized listening and monitoring of customers' assets such as offices, meeting rooms, real estates, personal vehicles, and conducting the tests of the insulation rooms they want to create.",
            pTwo: "As a result of the developing technology, everyday IT products such as security cameras, laptops, smartwatches, phones, routers might be used as hidden monitoring and listening tools. This, in return, makes it difficult to carry out TSCM activities with only radiofrequency analysis. Hence, a combined approach of radiofrequency and information security analyzes should be utilized. As a type of information security vulnerabilities, Technical Surveillance Countermeasures (TSCM) is the process of uncontrolled listening and monitoring information that will adversely affect many factors such as business life, social life, private life instantly or in a short time. While the information gathered within this process is used against you in a short time, it is sometimes used against you in a critical moment of your life by keeping it for a long time.",
            pThree: 'DEF24 carries out Technical Surveillance Countermeasures (TSCM), analysis of radiofrequency, detection of listening and monitoring devices, analysis of unusual power on phones, boats, sockets, and cables, and analysis of Wi-Fi, LAN, WLAN networks via manual scanning with licensed advanced RF detection products and thermal imaging devices.'
        }
    },
    privacy: {
        title: "Privacy-Policy",
        keywords: "privacy-policy, privacy, cookies",
        link: "/privacy",
        description: "DEF24 Inc. Privacy and Cookie Policy.",
        datasPolicy: {
            title: 'Privacy Policy',
            upperTitle: "PRIVACY POLICY",
            pOneTitle: "Article 1 - DEFINITIONS:",
            pOne: 'a) APPLICABLE WEBSITE: This Privacy Policy will refer to and be applicable to the Website listed above, which shall hereinafter be referred to as "Website." Any listed Website may also refer to a corresponding mobile application, should one be currently in use or hereinafter developed.',
            pTwo: 'b) EFFECTIVE DATE: "Effective Date" means the date this Privacy Policy comes into force and effect.',
            pThree: 'c) PARTIES: The parties to this privacy policy are the following data controller: Def24 Inc ("Data Controller") and you, as the user of this Website. Hereinafter, the parties will individually be referred to as "Party" and collectively as "Parties."',
            pFour: "d) DATA CONTROLLER: Data Controller is the publisher, owner, and operator of the Website and is the Party responsible for the collection of information described herein. Data Controller shall be referred to either by Data Controller's name or " + '"Data Controller,"' + " as listed above. If Data Controller or Data Controller's property shall be referred to through first-person pronouns, it shall be through the use of the following: us, we, our, ours, etc.",
            pFive: 'e) YOU: Should you agree to this Privacy Policy and continue your use of the Website, you will be referred to herein as either you, the user, or if any second-person pronouns are required and applicable, such pronouns as "your", "yours", etc.',
            pSix: 'f) SERVICES: "Services" means any services that we make available for sale on the Website.',
            pSeven: 'g) PERSONAL DATA: "Personal DATA" means personal data and information that we obtain from you in connection with your use of the Website that is capable of identifying you in any manner.',
            pEightTitle: 'Article 2 - GENERAL INFORMATION:',
            pEight: 'This privacy policy (hereinafter "Privacy Policy") describes how we collect and use the Personal Data that we receive about you, as well as your rights in relation to that Personal Data, when you visit our Website or use our Services.',
            pNine: 'This Privacy Policy does not cover any information that we may receive about you through sources other than the use of our Website. The Website may link out to other websites or mobile applications, but this Privacy Policy does not and will not apply to any of those linked websites or applications.',
            pTen: 'We are committed to the protection of your privacy while you use our Website.',
            pEleven: 'By continuing to use our Website, you acknowledge that you have had the chance to review and consider this Privacy Policy, and you acknowledge that you agree to it. This means that you also consent to the use of your information and the method of disclosure as described in this Privacy Policy. If you do not understand the Privacy Policy or do not agree to it, then you agree to immediately cease your use of our Website.',
            pTwelveTitle: 'Article 3 - CONTACT:',
            pTwelve: 'The Party responsible for the processing of your personal data is as follows: Def24 Inc. The Data Controller may be contacted as follows:',
            pThirteen: '815 Ponce De Leon Blvd, Second Floor, 33134 Coral Gables, Florida, USA',
            pFourtien: "The Data Controller and operator of the Website are one and the same.",

            pFifteenTitle: 'Article 4 - LOCATION:',
            pFifteen: "Please be advised the data processing activities take place in the United States, outside the European Economic Area. Data may also be transferred to companies within the United States, but will only be done so in a manner that complies with the EU's General Data Protection Regulation or GDPR. The location where the data processing activities take place is as follows:",
            pFifteenA: "United States",
            pSixteenTitle: 'Article 5 - MODIFICATIONS AND REVISIONS:',
            pSixteen: 'We reserve the right to modify, revise, or otherwise amend this Privacy Policy at any time and in any manner. If we do so, however, we will notify you and obtain your consent to the change in processing. Unless we specifically obtain your consent, any changes to the Privacy Policy will only impact the information collected on or after the date of the change. It is also your responsibility to periodically check this page for any such modification, revision or amendment.',
            pSeventeenTitle: 'Article 6 - THE PERSONAL DATA WE RECEIVE FROM YOU:',
            pSeventeen: "Depending on how you use our Website, you will be subject to different types of Personal Data collected and different manners of collection:",
            pEighteen: "a) Registered users: You, as a user of the Website, may be asked to register in order to use the Website or to purchase the Services available for sale.",
            pNineteen: "During the process of your registration, we will collect some of the following Personal Data from you through your voluntary disclosure:",
            pTwenty: "IP Address",
            pTwentyOne: "Personal Data may be asked for in relation to:",
            pTwentyOneList: [
                "I) Interaction with our representatives in any way",
                "II) making purchases",
                "III) receiving notifications by text message or email about marketing",
                "IV) receiving general emails from us",
                "V) commenting on our content or other user-generated content on our Website, such as blogs, articles, photographs or videos, or participating in our forums, bulletin boards, chat rooms or other similar features"
            ],
            pTwentyTwo: "By undergoing the registration process, you consent to us collecting your Personal Data, including the Personal Data described in this clause, as well as storing, using or disclosing your Personal Data in accordance with this Privacy Policy.",
            pTwentyThree: 'b) Unregistered users: If you are a passive user of the Website and do not register for any purchases or other service, you may still be subject to certain passive data collection ("Passive Data Collection"). Such Passive Data Collection may include through cookies, as described below, IP address information, location information, and certain browser data, such as history and/or session information.',
            pTwentyFour: "c) All users: The Passive Data Collection that applies to Unregistered users shall also apply to all other users and/or visitors of our Website.",
            pTwentyFive: "d) Sales & Billing Information: In order to purchase any of the services on the Website, you will be asked to provide certain credit information, billing address information, and possibly additional specific information so that you may be properly charged for your purchases. This payment and billing information will not be stored and will be used exclusively to assist with your one-time purchase.",
            pTwentySix: "e) Related Entities: We may share your Personal Data, including Personal Data that identifies you personally, with any of our parent companies, subsidiary companies, affiliates or other trusted related entities.",
            pTwentySeven: "However, we only share your Personal Data with a trusted related entity if that entity agrees to our privacy standards as set out in this Privacy Policy and to treat your Personal Data in the same manner that we do.",
            pTwentyEight: "f) Email Marketing: You may be asked to provide certain Personal Data, such as your name and email address, for the purpose of receiving email marketing communications. This information will only be obtained through your voluntary disclosure and you will be asked to affirmatively opt-in to email marketing communications.",
            pTwentyNine: "g) User Experience: From time to time we may request information from you to assist us in improving our Website, and the Services we sell, such as demographic information or your particular preferences.",
            pThirty: "h) Content Interaction: Our Website may allow you to comment on the content that we provide or the content that other users provide, such as blogs, multimedia, or forum posts. If so, we may collect some Personal Data from you at that time, such as, but not limited to, username or email address.",
            pThirtyOne: "i) Combined or Aggregated Information: We may combine or aggregate some of your Personal Data in order to better serve you and to better enhance and update our Website for your and other consumers' use.",
            pThirtyTwo: "We may also share such aggregated information with others.",
            pThirtyThreeTitle: "Article 7 - THE PERSONAL DATA WE RECEIVE AUTOMATICALLY:",
            p33List: ["Cookies: We may collect information from you through automatic tracking systems (such as information about your browsing preferences) as well as through information that you volunteer to us (such as information that you provide during a registration process or at other times while using the Website, as described above).",
                "For example, we use cookies to make your browsing experience easier and more intuitive: cookies are small strings of text used to store some information that may concern the user, his or her preferences or the device they are using to access the internet (such as a computer, tablet, or mobile phone). Cookies are mainly used to adapt the operation of the site to your expectations, offering a more personalized browsing experience and memorizing the choices you made previously.",
                "A cookie consists of a reduced set of data transferred to your browser from a web server and it can only be read by the server that made the transfer. This is not executable code and does not transmit viruses.",
                "Cookies do not record or store any Personal Data. If you want, you can prevent the use of cookies, but then you may not be able to use our Website as we intend. To proceed without changing the options related to cookies, simply continue to use our Website.",
                "Technical cookies: Technical cookies, which can also sometimes be called HTML cookies, are used for navigation and to facilitate your access to and use of the site. They are necessary for the transmission of communications on the network or to supply services requested by you. The use of technical cookies allows the safe and efficient use of the site.",
                "You can manage or request the general deactivation or cancelation of cookies through your browser. If you do this though, please be advised this action might slow down or prevent access to some parts of the site.",
                "Cookies may also be retransmitted by an analytics or statistics provider to collect aggregated information on the number of users and how they visit the Website. These are also considered technical cookies when they operate as described.",
                "Temporary session cookies are deleted automatically at the end of the browsing session - these are mostly used to identify you and ensure that you don't have to log in each time - whereas permanent cookies remain active longer than just one particular session.",
                "Third-party cookies: We may also utilize third-party cookies, which are cookies sent by a third-party to your computer. Permanent cookies are often third-party cookies. The majority of third-party cookies consist of tracking cookies used to identify online behavior, understand interests and then customize advertising for users.",
                "Third-party analytical cookies may also be installed. They are sent from the domains of the aforementioned third parties external to the site. Third-party analytical cookies are used to detect information on user behavior on our Website. This place anonymously, in order to monitor the performance and improve the usability of the site. Third-party profiling cookies are used to create profiles relating to users, in order to propose advertising in line with the choices expressed by the users themselves.",
                "Profiling cookies: We may also use profiling cookies, which are those that create profiles related to the user and are used in order to send advertising to the user's browser.",
                "When these types of cookies are used, we will receive your explicit consent.",
                "Support in configuring your browser: You can manage cookies through the settings of your browser on your device. However, deleting cookies from your browser may remove the preferences you have set for this Website.",
                "For further information and support, you can also visit the specific help page of the web browser you are using:"],
            p34List: [
                "Internet Explorer: http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies",
                "Firefox: https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences",
                "Safari: http://www.apple.com/legal/privacy/",
                "Chrome: https://support.google.com/accounts/answer/61416?hl=en",
                "Opera: http://www.opera.com/help/tutorials/security/cookies/"
            ],
            p35: "Log Data: Like all websites and mobile applications, this Website also makes use of log files that store automatic information collected during user visits. The different types of log data could be as follows:",
            p36List: [
                "- internet protocol (IP) address;",
                "- type of browser and device parameters used to connect to the Website;",
                "- name of the Internet Service Provider (ISP)",
                "- date and time of visit;",
                "- web page of origin of the user (referral) and exit",
                "- possibly the number of clicks"
            ],
            p37: "The aforementioned information is processed in an automated form and collected in an exclusively aggregated manner in order to verify the correct functioning of the site, and for security reasons. This information will be processed according to the legitimate interests of the Data Controller.",
            p38: "For security purposes (spam filters, firewalls, virus detection), the automatically recorded data may also possibly include Personal Data such as IP address, which could be used, in accordance with applicable laws, in order to block attempts at damage to the Website or damage to other users, or in the case of harmful activities or crime. Such data are never used for the identification or profiling of the user, but only for the protection of the Website and our users. Such information will be treated according to the legitimate interests of the Data Controller.",
            p50t: "Article 8 - THIRD PARTIES:",
            p50List: ['We may utilize third-party service providers ("Third-Party Service Providers"), from time to time or all the time, to help us with our Website, and to help serve you.',
                'We may use Third-Party Service Providers to assist with information storage (such as cloud storage).',
                'We may provide some of your Personal Data to Third-Party Service Providers in order to help us track usage data, such as referral websites, dates and times of page requests, etc. We use this information to understand patterns of usage of, and to improve, the Website.',
                'We may use Third-Party Service Providers to host the Website. In this instance, the Third-Party Service Provider will have access to your Personal Data.',
                'We may allow third parties to advertise on the Website. These third parties may use cookies in connection with their advertisements (see the "Cookies" clause in this Privacy Policy).',
                'We may use Third-Party Service Providers for the following services in relation to the Website:'],
            p51: 'Automatic Translation, Youtube',
            p52: 'Some of our Third-Party Service Providers may be located outside of the United States and may not be subject to U.S. privacy laws. The countries or regions in which our Third-Party Service Providers may be located include:',
            p53: 'Germany, Turkey',
            p54List: ['We only share your Personal Data with a Third-Party Service Provider if that provider agrees to our privacy standards as set out in this Privacy Policy.',
                'Your Personal Data will not be sold or otherwise transferred to other third parties without your approval.',
                'Notwithstanding the other provisions of this Privacy Policy, we may provide your Personal Data to a third party or to third parties in order to protect the rights, property or safety, of us, our customers or third parties, or as otherwise required by law.',
                'We will not knowingly share your Personal Data with any third parties other than in accordance with this Privacy Policy.',
                'If your Personal Data might be provided to a third party in a manner that is other than as explained in this Privacy Policy, you will be notified. You will also have the opportunity to request that we not share that information.',
                'In general, you may request that we do not share your Personal Data with third parties. Please contact us via email, if so. Please be advised that you may lose access to certain services that we rely on third-party providers for.'],
            p65t: 'Article 9 - SOCIAL NETWORK PLUGINS:',
            p65: 'This Website incorporates plugins and/or buttons for social networks, in order to allow easy sharing of content on your favorite social networks. These plugins are programmed so as not to set any cookies when accessing the page, to safeguard the privacy of users. Cookies may be set, however, if you make voluntary use of the plugin. Please note that if you browse while being logged into the social network, then you have already consented to the use of cookies conveyed through this Website at the time that you registered with the particular social network.',
            p66: 'The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks, which can be found below:',
            p66List: ['Facebook: https://www.facebook.com/help/cookies',
                'Twitter: https://help.twitter.com/en/rules-and-policies/twitter-cookies',
                'Google+: https://about.pinterest.com/it/privacy-policy',
                'Pinterest: https://about.pinterest.com/it/privacy-policy',
                'AddThis: http://www.addthis.com/privacy/privacy-policy',
                'Linkedin: https://www.linkedin.com/legal/cookie-policy'],
            p67t: 'Article 10 - HOW PERSONAL DATA IS STORED:',
            p68List: ['We use secure physical and digital systems to store your Personal Data when appropriate. We ensure that your Personal Data is protected against unauthorized access, disclosure, or destruction.',
                'Please note, however, that no system involving the transmission of information via the internet, or the electronic storage of data, is completely secure. However, we take the protection and storage of your Personal Data very seriously. We take all reasonable steps to protect your Personal Data.',
                'Personal Data is stored throughout your relationship with us. We delete your Personal Data upon request for cancelation of your account or other general request for the deletion of data.',
                'In the event of a breach of your Personal Data, you will be notified in a reasonable time frame, but in no event later than two weeks, and we will follow all applicable laws regarding such breach.'],
            p72t: 'Article 11 - PURPOSES OF PROCESSING OF PERSONAL DATA:',
            p73List: ['We primarily use your Personal Data to help us provide a better experience for you on our Website and to provide you the services and/or information you may have requested, such as use of our Website.',
                'Information that does not identify you personally, but that may assist in providing us broad overviews of our customer base, will be used for market research or marketing efforts. Such information may include, but is not limited to, interests based on your cookies.',
                'Personal Data that may be considering identifying may be used for the following:',
                'a) Improving your personal user experience',
                'b) Communicating with you about your user account with us',
                'c) Marketing and advertising to you, including via email',
                'd) Fulfilling your purchases',
                'e) Providing customer service to you',
                'f) Advising you about updates to the Website or related Items'],
            p76t: 'Article 12 - DISCLOSURE OF PERSONAL DATA:',
            p76List: ['Although our policy is to maintain the privacy of your Personal Data as described herein, we may disclose your Personal Data if we believe that it is reasonable to do so in certain cases, in our sole and exclusive discretion. Such cases may include, but are not limited to:',
                'a) To satisfy any local, state, or Federal laws or regulations',
                'b) To respond to requests, such discovery, criminal, civil, or administrative process, subpoenas, court orders, or writs from law enforcement or other governmental or legal bodies',
                'c) To bring legal action against a user who has violated the law or violated the terms of use of our Website',
                'd) As may be necessary for the operation of our Website',
                'e) To generally cooperate with any lawful investigation about our users',
                'f) If we suspect any fraudulent activity on our Website or if we have noticed any activity which may violate our terms or other applicable rules'],
            p77t: 'Article 13 - OPTING OUT OF TRANSMITTALS FROM US:',
            p77: 'From time to time, we may send you informational or marketing communications related to our Website such as announcements or other information. If you wish to opt-out of such communications, you may contact the following email:',
            p77List: [
                'You may also click the opt-out link which will be provided at the bottom of any and all such communications.',
                'Please be advised that even though you may opt-out of such communications, you may still receive information from us that is specifically about your use of our Website or about your account with us.',
                'By providing any Personal Data to us, or by using our Website in any manner, you have created a commercial relationship with us. As such, you agree that any email sent from us or third-party affiliates, even unsolicited email, shall specifically not be considered SPAM, as that term is legally defined.'],
            p80t: 'Article 14 - MODIFYING, DELETING, AND ACCESSING YOUR INFORMATION:',
            p80: 'If you wish to modify or delete any information we may have about you, or you wish to simply access any information we have about you, you may reach out to us at the following email address:',
            p81t: 'Article 15 - ACCEPTANCE OF RISK:',
            p82: 'By continuing to our Website in any manner, use the Product, you manifest your continuing asset to this Privacy Policy. You further acknowledge, agree and accept that no transmission of information or data via the internet is not always completely secure, no matter what steps are taken. You acknowledge, agree and accept that we do not guarantee or warrant the security of any information that you provide to us, and that you transmit such information at your own risk.',
            p83t: 'Article 16 - YOUR RIGHTS:',
            p83: 'You have many rights in relation to your Personal Data. Specifically, your rights are as follows:',
            p84List: ['- the right to be informed about the processing of your Personal Data',
                '- the right to have access to your Personal Data',
                '- the right to update and/or correct your Personal Data',
                '- the right to portability of your Personal Data',
                '- the right to oppose or limit the processing of your Personal Data',
                '- the right to request that we stop processing and delete your Personal Data',
                '- the right to block any Personal Data processing in violation of any applicable law',
                '- the right to launch a complaint with the Federal Trade Commission (FTC) in the United States or applicable data protection authority in another jurisdiction'],
            p85: 'Such rights can all be exercised by contacting us at the relevant contact information listed in this Privacy Policy.',
            p86t: 'Article 17 - CONTACT INFORMATION:',
            p86: 'If you have any questions about this Privacy Policy or the way we collect information from you, or if you would like to launch a complaint about anything related to this Privacy Policy, you may contact us at the following email address:',
        }
    },
    terms_conditions: {
        title: "Terms & Conditions",
        keywords: "terms of conditions",
        link: "/terms-conditions",
        description: "DEF24 Inc. Terms of Conditions.",
        datas: {
            documentTitle: 'Terms & Conditions',
            pOne: "THE AGREEMENT: The use of this website and services on this website provided by Def24 Inc (hereinafter referred to as" + '"Company"' + ") are subject to the following Terms & Conditions (hereinafter the" + '"Agreement"' + "), all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as" + '"Website"' + ") and any services provided by or on this Website (" + '"Services"' + ").",
            pTwoTitle: '1) DEFINITIONS',
            pTwo: "The parties referred to in this Agreement shall be defined as follows:",
            pThree: "Company, Us, We: The Company, as the creator, operator, and publisher of the Website, makes the Website, and certain Services on it, available to users. Def24 Inc, Company, Us, We, Our, Ours and other first-person pronouns will refer to the Company, as well as all employees and affiliates of the Company.",
            pFour: "You, the User, the Client: You, as the user of the Website, will be referred to throughout this Agreement with second-person pronouns such as You, Your, Yours, or as User or Client.",
            pFive: "Parties: Collectively, the parties to this Agreement (the Company and You) will be referred to as Parties.",
            pSixTitle: "2) ASSENT & ACCEPTANCE",
            pSeven: "By using the Website, You warrant that You have read and reviewed this Agreement and that You agree to be bound by it. If You do not agree to be bound by this Agreement, please leave the Website immediately. The Company only agrees to provide use of this Website and Services to You if You assent to this Agreement.",
            pEightTitle: "3) AGE RESTRICTION",
            pEight: "You must be at least 18 (eighteen) years of age to use this Website or any Services contained herein. By using this Website, You represent and warrant that You are at least 18 years of age and may legally agree to this Agreement. The Company assumes no responsibility or liability for any misrepresentation of Your age.",
            pFourteenTitle: "4) LICENSE TO USE WEBSITE",
            pFourteen: 'The Company may provide You with certain information as a result of Your use of the Website or Services. Such information may include, but is not limited to, documentation, data, or information developed by the Company, and other materials which may assist in Your use of the Website or Services ("Company Materials"). Subject to this Agreement, the Company grants You a non-exclusive, limited, non-transferable and revocable license to use the Company Materials solely in connection with Your use of the Website and Services. The Company Materials may not be used for any other purpose, and this license terminates upon Your cessation of use of the Website or Services or at the termination of this Agreement.',
            pSeventeenTitle: "5) INTELLECTUAL PROPERTY",
            pSeventeen: 'You agree that the Website and all Services provided by the Company are the property of the Company, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Company IP"). You agree that the Company owns all right, title and interest in and to the Company IP and that You will not use the Company IP for any unlawful or infringing purpose. You agree not to reproduce or distribute the Company IP in any way, including electronically or via registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), without express written permission from the Company.',
            pEighteen: 'a) In order to make the Website and Services available to You, You hereby grant the Company a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content You publish, upload, or otherwise make available to the Website ("Your Content"). The Company claims no further proprietary rights in Your Content.',
            pTwenty: "b) If You feel that any of Your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of Our users, please contact Us and let Us know.",
            pTwentyThreeTitle: "6) USER OBLIGATIONS",
            pTwentyThree: "As a user of the Website or Services, You may be asked to register with Us. When You do so, You will choose a user identifier, which may be Your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, Your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable You to use the Website and Services. You must not share such identifying information with any third party, and if You discover that Your identifying information has been compromised, You agree to notify Us immediately in writing. Email notification will suffice. You are responsible for maintaining the safety and security of Your identifying information as well as keeping Us apprised of any changes to Your identifying information. Providing false or inaccurate information, or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.",
            pTwentyFourTitle: "7) ACCEPTABLE USE",
            pTwentyFour: "You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of the Company.",
            pTwentyFourA: "a) You further agree not to use the Website or Services:",
            pTwentyFourList: ["I) To harass, abuse, or threaten others or otherwise violate any person's legal rights;",
                "II) To violate any intellectual property rights of the Company or any third party;",
                "III) To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;",
                "IV) To perpetrate any fraud;",
                "V) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;",
                "VI) To publish or distribute any obscene or defamatory material;",
                "VII) To publish or distribute any material that incites violence, hate, or discrimination towards any group;",
                "VIII) To unlawfully gather information about others."],
            pTwentyFiveTitle: "8) AFFILIATE MARKETING & ADVERTISING",
            pTwentyFive: "The Company, through the Website and Services, may engage in affiliate marketing whereby the Company receives a commission on or percentage of the sale of goods or services on or through the Website. The Company may also accept advertising and sponsorships from commercial businesses or receive other forms of advertising compensation. This disclosure is intended to comply with the US Federal Trade Commission Rules on marketing and advertising, as well as any other legal requirements which may apply.",
            pTwentySixTitle: "9) PRIVACY INFORMATION",
            pTwentySix: "Through Your Use of the Website and Services, You may provide Us with certain information. By using the Website or the Services, You authorize the Company to use Your information in the United States and any other country where We may operate.",
            pTwentySevenTitle: "10) ASSUMPTION OF RISK",
            pTwentySeven: "The Website and Services are provided for communication purposes only. You acknowledge and agree that any information posted on Our Website is not intended to be legal advice, medical advice, or financial advice, and no fiduciary relationship has been created between You and the Company. You further agree that Your purchase of any of the products on the Website is at Your own risk. The Company does not assume responsibility or liability for any advice or other information given on the Website.",
            pTwentyEightTitle: "11) SALES",
            pTwentyEight: "The Company may sell goods or services or allow third parties to sell goods or services on the Website. The Company undertakes to be as accurate as possible with all information regarding the goods and services, including product descriptions and images. However, the Company does not guarantee the accuracy or reliability of any product information, and You acknowledge and agree that You purchase such products at Your own risk.",
            pTwentyNineTitle: "12) REVERSE ENGINEERING & SECURITY",
            pTwentyNine: "You agree not to undertake any of the following actions:",
            pTwentyNineA: "a) Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;",
            pTwentyNineB: 'b) Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.',
            pThirtyTitle: "13) DATA LOSS",
            pThirty: "The Company does not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at Your own risk.",
            pThirtyOneTitle: "14) INDEMNIFICATION",
            pThirtyOne: "You agree to defend and indemnify the Company and any of its affiliates (if applicable) and hold Us harmless against any and all legal claims and demands, including reasonable attorney's fees, which may arise from or relate to Your use or misuse of the Website or Services, Your breach of this Agreement, or Your conduct or actions. You agree that the Company shall be able to select its own legal counsel and may participate in its own defense, if the Company wishes.",
            pThirtyTwoTitle: "15) SPAM POLICY",
            pThirtyTwo: "You are strictly prohibited from using the Website or any of the Company's Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.",
            pThirtyThreeTitle: "16) THIRD-PARTY LINKS & CONTENT",
            pThirtyThree: "The Company may occasionally post links to third party websites or other services. You agree that the Company is not responsible or liable for any loss or damage caused as a result of Your use of any third party services linked to from Our Website.",
            pThirtyFourTitle: "17) MODIFICATION & VARIATION",
            pThirtyFour: "The Company may, from time to time and at any time without notice to You, modify this Agreement. You agree that the Company has the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement, unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.",
            pThirtyFourA: "a) To the extent any part or sub-part of this Agreement is held ineffective or invalid by any court of law, You agree that the prior, effective version of this Agreement shall be considered enforceable and valid to the fullest extent.",
            pThirtyFourB: "b) You agree to routinely monitor this Agreement and refer to the Effective Date posted at the top of this Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of this Agreement. You agree that Your continued use of the Website after any modifications to this Agreement is a manifestation of Your continued assent to this Agreement.",
            pThirtyFourC: "c) In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.",
            pThirtyFiveTitle: "18) ENTIRE AGREEMENT",
            pThirtyFive: "This Agreement constitutes the entire understanding between the Parties with respect to any and all use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.",
            pThirtySixTitle: "19) SERVICE INTERRUPTIONS",
            pThirtySix: "The Company may need to interrupt Your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that Your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that the Company shall have no liability for any damage or loss caused as a result of such downtime.",
            pThirtySevenTitle: "20) TERM, TERMINATION & SUSPENSION",
            pThirtySeven: "The Company may terminate this Agreement with You at any time for any reason, with or without cause. The Company specifically reserves the right to terminate this Agreement if You violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of the Company or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If You have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.",
            pThirtyEightTitle: "21) NO WARRANTIES",
            pThirtyEight: 'You agree that Your use of the Website and Services is at Your sole and exclusive risk and that any Services provided by Us are on an "As Is" basis. The Company hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. The Company makes no warranties that the Website or Services will meet Your needs or that the Website or Services will be uninterrupted, error-free, or secure. The Company also makes no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to You, through Your computer system, or as a result of loss of Your data from Your use of the Website or Services is Your sole responsibility and that the Company is not liable for any such damage or loss.',
            pThirtyNineTitle: "22) LIMITATION ON LIABILITY",
            pThirtyNine: 'The Company is not liable for any damages that may occur to You as a result of Your use of the Website or Services, to the fullest extent permitted by law. The maximum liability of the Company arising from or relating to this Agreement is limited to the greater of one hundred ($100) US Dollars or the amount You paid to the Company in the last six (6) months. This section applies to any and all claims by You, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.',
            pFourtyTitle: "23) GENERAL PROVISIONS:",
            pFourty: "a) LANGUAGE: All communications made or notices given pursuant to this Agreement shall be in the English language.",
            pFourtyA: "b) JURISDICTION, VENUE & CHOICE OF LAW: Through Your use of the Website or Services, You agree that the laws of the State of Florida shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between You and the Company, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of the state and federal courts of the following county: Miami Dade, Florida. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine.",
            pFourtyB: "c) ARBITRATION: In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration. The arbitration shall be conducted in the following county: Miami Dade. The arbitration shall be conducted by a single arbitrator, and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by applicable and governing Federal law as well as the law of the following state: Florida. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims, claims based on Federal and state law, and claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by the Company will not be subject to arbitration and may, as an exception to this sub-part, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims."
        }
    },
    errorPage: {
        title: "404 Error Page",
        keywords: "404, error page",
        link: "/404",
        description: "Error Page",
        definition: "404, Not Found."

    }

}
